import React from "react";
import {useSelector} from "react-redux";
import {getMusikProduktionIdGetter} from "src/features/entity";
import {Chip, IconButton, Tooltip} from "@material-ui/core";
import {MUSIK_PRODUKTION_ID_TYP_KNZ} from "src/features/dashboard/dashboard-validation";
import {Edit, Warning} from "@material-ui/icons";

function MusikProduktionIdTyp({knz}) {
  let description = MUSIK_PRODUKTION_ID_TYP_KNZ[knz];
  if (description === undefined) {
    return null;
  }

  return (
    <Chip label={description} size="small"/>
  );
}

export default function MusikProduktionId({id, showType, onEdit, hideEdit, ...props}) {
  const getMusikProduktionId = useSelector(getMusikProduktionIdGetter);

  const {organization, id_typ_knz, id_wert, is_valid} = getMusikProduktionId(id);

  const typeBadge = showType ? (
    <MusikProduktionIdTyp knz={id_typ_knz} {...props}/>
  ) : null;

  const isInvalid = is_valid === false;

  return (
    <>
      {isInvalid ? (
        <Tooltip title="Diese Produktion-ID ist ungültig.">
          <Chip label={id_wert} color="primary" size="small" icon={<Warning/>}/>
        </Tooltip>
      ) : (
        <>
          {id_wert}
        </>
      )}
      {typeBadge ? (
        <>
          {' '}
          {typeBadge}
        </>
      ) : null}
      {(onEdit && (!hideEdit || isInvalid)) ? (
        <>
          {' '}
          <IconButton
            aria-label="edit"
            onClick={onEdit ? (() => onEdit({id, organization, id_typ_knz, id_wert})) : null}
            size="small"
          >
            <Edit/>
          </IconButton>
        </>
      ) : null}
    </>
  );
}
