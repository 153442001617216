import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {MUSIC_PRODUCTION_ID_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useEntityDeleter} from "src/features/entity/entity-hooks";
import {MUSIK_PRODUKTION_ID} from "src/api/api-schemas";

export function MusicProductionIDForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(MUSIK_PRODUKTION_ID);

  const save = async (validatedData) => {
    const {organization, id} = validatedData;

    if (id) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${organization}/musik_produktion_ids/${id}/`,
        validatedData,
      );
    } else {
      await entityApi.post(
        `/api/sendemeldung/organizations/${organization}/musik_produktion_ids/`,
        validatedData,
        {createEntities: true, organization},
      );
    }

    // TODO: Generalize save mechanism.
  };

  const {deleteEntity, deletingUuids} = useEntityDeleter({
    entityType: 'musik_produktion_id',
    baseUrl: `/api/sendemeldung/organizations/${data?.organization}/musik_produktion_ids/`,
  });

  return (
    <SimpleYupFormDialog
      id={'edit-music-production-id'}
      title={data?.id ? "Produktion-ID bearbeiten" : "Produktion-ID anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={save}
      schema={MUSIC_PRODUCTION_ID_SCHEMA}
      onDelete={
        // TODO: Implement suitable confirmation mechanism.
        // TODO: Track and display deletion status.
        data?.id ? (
          () => confirm("Produktion-ID wirklich löschen?") && deleteEntity(data?.id)
        ) : undefined
      }
      deleteCaption="Produktion-ID löschen"
      isDeleting={deletingUuids?.has(data?.id)}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <YupField name="id_typ_knz"/>
          </Grid>
          <Grid item xs={6}>
            <YupField name="id_wert"/>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
