import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {ERSCHIENENE_TONAUFNAHME_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useEntityDeleter} from "src/features/entity/entity-hooks";
import {ERSCHIENENE_TONAUFNAHME} from "src/api/api-schemas";
import {useSelector} from "react-redux";
import {getSelectedOrganization} from "src/features/dashboard";

export function ErschieneneTonaufnahmeForm({data, onClose, ...props}) {
  const selectedOrganization = useSelector(getSelectedOrganization);
  const entityApi = useEntityApi(ERSCHIENENE_TONAUFNAHME);

  const {id} = data;

  const saveWork = async (validatedData) => {
    const {organization, id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/organizations/${organization}/erschienene_tonaufnahmen/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  const {deleteEntity, deletingUuids} = useEntityDeleter({
    entityType: 'erschienene_tonaufnahmen',
    baseUrl: `/api/sendemeldung/organizations/${selectedOrganization?.id}/erschienene_tonaufnahmen/`,
  });

  return (
    <SimpleYupFormDialog
      id={'edit-erschienene_tonaufnahme'}
      title={data?.id ? "Erschienene Tonaufnahme bearbeiten" : "Erschienene Tonaufnahme anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveWork}
      onDelete={
        // TODO: Implement suitable confirmation mechanism.
        // TODO: Track and display deletion status.
        id ? (
          () => confirm("Tonaufnahme wirklich löschen?") && deleteEntity(id)
        ) : undefined
      }
      deleteCaption="Tonaufnahme löschen"
      isDeleting={deletingUuids?.has(id)}
      schema={ERSCHIENENE_TONAUFNAHME_SCHEMA}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YupField name="deklaration_tonaufnahme_knz"/>
          </Grid>
          <Grid item xs={4}>
            <YupField name="label_code"/>
          </Grid>
          <Grid item xs={8}>
            <YupField name="label"/>
          </Grid>
          <Grid item xs={7}>
            <YupField name="album_titel"/>
          </Grid>
          <Grid item xs={5}>
            <YupField name="voe_datum"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="sonstige_info"/>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
