import {useSelector} from "react-redux";
import {getOrgMusicWorkGetter} from "src/features/entity";
import {Link} from "gatsby-theme-material-ui";
import {Chip, IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {Add, Edit} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import {MusicWorkForm} from "src/components/entities/musicwork/MusicWorkForm";

export default function MusikTitel({id, createLink, onEdit}) {
  const {
    id: musikTitelId,
    organization,
    musik_titel,
    nutzung_art_knz,
      recht_knz,
      besetzung_knz,
      gattung,
    is_silence,
  } = useSelector(getOrgMusicWorkGetter)(id);

  const [editMusicWork, setEditMusicWork] = useState(null);

  let renderedTitle = musik_titel;

  if (!musikTitelId) {
    return (
      <Skeleton variant="text"/>
    );
  }

  const addTitleButton = (
    <Chip
      icon={<Add/>}
      variant="outlined"
      color="primary"
      clickable
      label="Titel ergänzen"
    onClick={() => setEditMusicWork({
      id,
      organization,
      musik_titel,
      recht_knz,
      besetzung_knz,
      gattung,
      is_silence,
    })}
    />
  );

  if (!renderedTitle) {
    renderedTitle = addTitleButton;
  }

  if (createLink && musikTitelId && musik_titel) {
    renderedTitle = (
      <Link to={`/dashboard/archive/${id}`} color="inherit">
        {renderedTitle}
      </Link>
    );
  }

  return (
    <>
      {renderedTitle}
      {nutzung_art_knz === 'JINGLE' ? (
        <>
          {' '}
          <Chip size="small" label="Jingle"/>
        </>
      ) : null}
      {is_silence ? (
        <>
          {' '}
          <Chip size="small" label="Stille"/>
        </>
      ) : null}
      {onEdit ? (
        <>
          {' '}
          <IconButton
            aria-label="edit"
            onClick={onEdit ? (() => onEdit({id, organization, musik_titel})) : null}
            size="small"
          >
            <Edit/>
          </IconButton>
        </>
      ) : null}

      {editMusicWork ? (
        <div align="left">
            <MusicWorkForm
              data={editMusicWork}
              onClose={() => setEditMusicWork(null)}
            />
        </div>
      ) : null}
    </>
  );
}
