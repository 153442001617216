import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback} from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  IconButton, ListItemIcon, ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import {Check, Delete, Error, ErrorOutline, ExpandMore, GetApp, MoreHoriz, Warning} from "@material-ui/icons";
import CircularProgressWithLabel from "src/components/core/CircularProgressWithLabel";
import FileIcon from "src/components/entities/upload/FileIcon";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  icon: {
    flex: 0,
    paddingRight: theme.spacing(2),
    minWidth: 48,
    color: theme.palette.action.active,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50.0%',
    flexShrink: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    //flexBasis: '100.0%',
    //flexShrink: 0,
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  fileHash: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    '& > span': {
      verticalAlign: 'middle',
      display: 'inline-block',
      maxWidth: '3.5rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontFamily: 'monospace',
      fontSize: '0.9rem',
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandedAccordion: {
    // background: theme.palette.action.hover,
  },
}));

export default function FileRow(
  {
    id,
    name,
    expanded = false,
    expansible,
    type,
    clientInProgress = false,
    clientFailed = false,
    serverInProgress = false,
    serverSuccessful = false,
    serverFailed = false,
    serverWarning = false,
    contentHash,
    showContentHash = false,
    clientStatus,
    serverStatus,
    onChange,
    downloadUrl,
    primaryActions,
    children,
    onDelete,
    serverProgress,
    ...props
  },
) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const [isExpanded, setIsExpanded] = React.useState(expanded);
  const simpleOnChange = useCallback((event, changedExpanded) => {
    setIsExpanded(changedExpanded);
  }, [setIsExpanded]);

  if (onChange) {
    expansible = true;
  }

  expanded = expanded || isExpanded;
  onChange = onChange || simpleOnChange;

  return (
    <Accordion expanded={expanded} onChange={expansible ? onChange : null} TransitionProps={{unmountOnExit: true}}
               {...props}>
      <AccordionSummary
        expandIcon={expansible ? (<ExpandMore/>) : null}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Grid container style={{alignItems: 'center'}}>

          <Grid item container xs={6} style={{alignItems: 'center'}}>
            <div className={classes.icon}>
              {clientInProgress === true ? (
                <CircularProgressWithLabel size="2rem"/>
              ) : (clientFailed) ? (
                <ErrorOutline color="primary" fontSize="large"/>
              ) : (clientInProgress !== false) ? (
                <CircularProgressWithLabel size="2rem" value={clientInProgress}/>
              ) : (
                <FileIcon type={type}/>
              )}
            </div>
            <div className={classes.heading2}>
              <Typography className={classes.heading2}>
                <span title={name}>{name}</span>
                {(showContentHash && contentHash) ? (
                  <small className={classes.fileHash}>
                    <Tooltip
                      title={(
                        <>
                          Fingerabdruck des Dateiinhalts: {contentHash}<br/><br/>
                          Der Fingerabdruck dient der Unterscheidung gleichnamiger Dateien mit unterschiedlichem Inhalt.
                        </>
                      )}
                      arrow
                    >
                      <span>#{contentHash}</span>
                    </Tooltip>
                  </small>
                ) : null}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {clientStatus}
              </Typography>
            </div>
          </Grid>

          <Grid item container xs={6} style={{alignItems: 'center'}}>
            <div className={classes.icon}>
              {serverInProgress ? (
                <CircularProgressWithLabel size="2rem" color="inherit" value={serverProgress}/>
              ) : serverSuccessful ? (
                <Check color="action" fontSize="large"/>
              ) : serverFailed ? (
                <Error color="primary" fontSize="large"/>
              ) : serverWarning ? (
                <Warning color="primary" fontSize="large"/>
              ) : null}
            </div>
            <div className={classes.heading2}>
              <Typography className={classes.secondaryHeading}>
                {serverStatus}
              </Typography>
            </div>
          </Grid>

        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          {children}
        </div>
      </AccordionDetails>
      {downloadUrl || onDelete || primaryActions ? (
        <>
          <Divider/>
          <AccordionActions>
            {downloadUrl ? (
              <IconButton href={downloadUrl}>
                <GetApp/>
              </IconButton>
            ) : null}
            {onDelete ? (
              <>
                <IconButton
                  aria-label="more actions"
                  aria-controls={`${id}-more-menu`}
                  aria-haspopup="true"
                  onClick={openMenu}
                  color="inherit"
                >
                  <MoreHoriz/>
                </IconButton>
                <Menu
                  id={`${id}-more-menu`}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={menuOpen}
                  onClose={closeMenu}
                >
                  {onDelete ? (
                    <MenuItem onClick={onDelete}>
                      <ListItemIcon>
                        <Delete fontSize="small"/>
                      </ListItemIcon>
                      <ListItemText>
                        Datei löschen
                      </ListItemText>
                    </MenuItem>
                  ) : null}
                </Menu>
              </>
            ) : null}
            <div style={{flex: 1}}/>
            {primaryActions}
          </AccordionActions>
        </>
      ) : null}
    </Accordion>
  );
}
