import React, {useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {getSelectedOrganization} from "src/features/dashboard";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {Add, AddLocation, Business, Edit, ExpandMore} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import OrganizationDetailsForm from "src/components/entities/user/OrganizationDetailsForm";
import LieferantIDFormDialog from "src/components/entities/user/LieferantIDFormDialog";
import GEMAGVL4ImportSettingsFormDialog from "src/components/entities/user/GEMAGVL4ImportSettingsFormDialog";
import {TIMEZONES} from "src/packages/timezones";
import {MUSIK_HERKUNFT_KNZ} from "src/features/dashboard/dashboard-validation";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  multiColumn: {
    flexBasis: '66.67%',
  },
}));

function SettingAccordion({id, title, description, onEdit, children, ...props}) {
  const classes = useStyles();

  return (
    <Accordion {...props}>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Grid container style={{alignItems: 'center'}}>
          <Grid item container xs={11} style={{alignItems: 'left'}}>
            <div className={classes.column}>
              <Typography className={classes.heading}>{title}</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}>
                {description}
              </Typography>
            </div>
          </Grid>
          <Grid item container xs={1} style={{alignItems: 'right'}}>
            {onEdit ? (
              <IconButton
                aria-label="edit"
                onClick={onEdit ? ((event) => {
                  event.stopPropagation();
                  onEdit();
                }) : null}
              >
                <Edit/>
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default function OrganizationPage() {
  const organization = useSelector(getSelectedOrganization);
  const {
    customer_id,
    company_name,
    address,
    gemagvlxml_lieferant_id,
    gemagvl4_encoding_preferences,
    gemagvl4_timezone,
    jingle_musik_herkunft_knz,
    gemagvl_test_priority,
    is_gemagvl_approved: isApproved,
  } = organization;
  const displayAddress = address?.replace(/\r?\n/g, ', ');

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [editOrganization, setEditOrganization] = useState(null);
  const [editLieferantID, setEditLieferantID] = useState(null);
  const [editImportSettings, setEditImportSettings] = useState(null);

  return (
    <DashboardLayout
      titlePrefix="Stammdaten"
      selectedPage="organization"
      drawerContentProps={{expandSettings: true}}
    >
      <Box my={2}>
        <Typography variant="h4">
          Stammdaten Ihres Programmveranstalters
        </Typography>
      </Box>

      <div className={classes.root}>
        <SettingAccordion
          id="details"
          expanded={expanded === 'details'}
          onChange={handleChange('details')}
          onEdit={() => setEditOrganization(organization)}
          title="Firmenname"
          description={(
            <>
              {company_name ? (
                <>
                  {company_name}
                </>
              ) : (
                <Chip
                  icon={<Business/>}
                  label="Firmenname ergänzen"
                  variant="outlined"
                  clickable
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditOrganization(organization);
                  }}
                />
              )}
            </>
          )}
        >
        </SettingAccordion>

        <SettingAccordion
          id="address"
          expanded={expanded === 'address'}
          onChange={handleChange('address')}
          onEdit={() => setEditOrganization(organization)}
          title="Adresse"
          description={(
            <>
              {displayAddress ? displayAddress : (
                <Chip
                  icon={<AddLocation/>}
                  label="Adresse ergänzen"
                  variant="outlined"
                  clickable
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditOrganization(organization);
                  }}
                />
              )}
            </>
          )}
        >
        </SettingAccordion>

        <SettingAccordion
          id="customer_id"
          expanded={expanded === 'customer_id'}
          onChange={handleChange('customer_id')}
          title="Kundennummer"
          description={customer_id}
        >
          <Typography>
            Ihre Kundenummer lautet <strong>{customer_id}</strong>. Bitte geben Sie diese bei Rückfragen an.
          </Typography>
        </SettingAccordion>

        <br/>

        <SettingAccordion
          id="gemagvlxml_lieferant_id"
          expanded={expanded === 'gemagvlxml_lieferant_id'}
          onChange={handleChange('gemagvlxml_lieferant_id')}
          onEdit={() => setEditLieferantID(organization)}
          title="GEMA / GVL"
          description={(
            <>
              {gemagvlxml_lieferant_id ? (
                <>
                  {gemagvlxml_lieferant_id}
                  {' '}
                  <Chip label="Lieferant-ID" size="small"/>
                </>
              ) : (
                <Chip
                  icon={<Add/>}
                  label="Lieferant-ID ergänzen"
                  variant="outlined"
                  clickable
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditLieferantID(organization);
                  }}
                />
              )}
            </>
          )}
        >
          <div>
            {!gemagvlxml_lieferant_id ? (
              <Alert variant="outlined" severity="info">
                Ihre Lieferant-ID erhalten Sie von den Verwertungsgesellschaften GEMA bzw. GVL.
              </Alert>
            ) : null}
            {gemagvl_test_priority && !isApproved ? (
              <Alert variant="outlined" severity="info">
                Für die Testwochen wurde Ihrem Haus durch die Verwertungsgesellschaften die Priorität
                {' '}
                <strong>{gemagvl_test_priority}</strong> zugeordnet.
              </Alert>
            ) : null}
          </div>
        </SettingAccordion>

        <br/>

        <SettingAccordion
          id="gemagvl4_encoding"
          expanded={expanded === 'gemagvl4_encoding'}
          onChange={handleChange('gemagvl4_encoding')}
          onEdit={() => setEditImportSettings(organization)}
          title="GEMAGVL4-Zeichensatz"
          description={(
            <>
              {!gemagvl4_encoding_preferences?.length ? (
                "(automatische Erkennung)"
              ) : (
                gemagvl4_encoding_preferences?.map((encoding) =>
                  encoding === null ? "(automatische Erkennung)" : encoding
                )?.join(', ')
              )}
            </>
          )}
        >
          <Typography>
            Beim Import von GEMAGVL4-Sendemeldungen wird der angegebene Zeichensatz verwendet. Sind mehrere
            Zeichensätze konfiguriert, so wird der erste Zeichensatz verwendet, mit dem die Datei vollständig
            dekodierbar ist.
          </Typography>
        </SettingAccordion>

        <SettingAccordion
          id="gemagvl4_timezone"
          expanded={expanded === 'gemagvl4_timezone'}
          onChange={handleChange('gemagvl4_timezone')}
          onEdit={() => setEditImportSettings(organization)}
          title="GEMAGVL4-Zeitzone"
          description={(
            <>
              {!gemagvl4_timezone ? (
                "MEZ"
              ) : (
                TIMEZONES[gemagvl4_timezone] || gemagvl4_timezone
              )}
            </>
          )}
        >
          <Typography>
            Beim Import von GEMAGVL4-Sendemeldungen werden Zeitangaben der genannten Zeitzone zugeordnet.
          </Typography>
        </SettingAccordion>

        <SettingAccordion
          id="jingle_musik_herkunft_knz"
          expanded={expanded === 'jingle_musik_herkunft_knz'}
          onChange={handleChange('jingle_musik_herkunft_knz')}
          onEdit={() => setEditImportSettings(organization)}
          title="Musikherkunft Ihrer Jingles"
          description={(
            <>
              {!jingle_musik_herkunft_knz ? (
                "(keine Auswahl)"
              ) : (
                MUSIK_HERKUNFT_KNZ[jingle_musik_herkunft_knz]
              )}
            </>
          )}
        >
          <Typography>
            Bei GEMAGVL-XML-Meldungen muss stets die jeweilige Musikherkunft angegeben werden.
            Diese Information ist in GEMAGVL4-Meldungen nicht enthalten.
            Hier können Sie auswählen, aus welcher Herkunft Ihre Jingles typischerweise stammen &ndash; diese wird dann
            für neu importierte Jingles voreingestellt.
          </Typography>
        </SettingAccordion>
      </div>

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editOrganization ? (
        <OrganizationDetailsForm
          data={editOrganization}
          onClose={() => setEditOrganization(null)}
        />
      ) : null}

      {editLieferantID ? (
        <LieferantIDFormDialog
          data={editLieferantID}
          onClose={() => setEditLieferantID(null)}
        />
      ) : null}

      {editImportSettings ? (
        <GEMAGVL4ImportSettingsFormDialog
          data={editImportSettings}
          onClose={() => setEditImportSettings(null)}
        />
      ) : null}

    </DashboardLayout>
  );
}
