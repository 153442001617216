import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {GEMAGVLXML_AUSSTRAHLUNG_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useEntityDeleter, useEntityObserver} from "src/features/entity/entity-hooks";
import {GEMAGVLXML_AUSSTRAHLUNG} from "src/api/api-schemas";
import {getGEMAGVL4AusstrahlungGetter, getGEMAGVLXMLAusstrahlungGetter} from "src/features/entity";
import {useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";
import {differenceInSeconds, formatDuration, formatISO, formatISODuration, parseISO} from "date-fns";
import {Alert} from "@material-ui/lab";
import {parse as parseISODuration} from 'iso8601-duration';
import deLocale from "date-fns/locale/de";
import {getSelectedOrganization} from "src/features/dashboard";

function tryFormatISO(dt) {
  try {
    if (dt instanceof Date) {
      return formatISO(dt);
    }
  } catch (e) {
  }
  return dt;
}

function allowedValues(...args) {
  return args.filter((x) => x);
}

const DURATION_RE = /^(\d+):(\d+):(\d+)$/;

function parseDuration(duration) {
  if (!duration) {
    return null;
  }

  const match = DURATION_RE.exec(duration);
  if (match) {
    return {
      ...parseDuration('P'),
      hours: parseInt(match[1]),
      minutes: parseInt(match[2]),
      seconds: parseInt(match[3]),
    };
  }

  return parseISODuration(duration);
}

function normalizeDuration(duration) {
  if (!duration) {
    return null;
  }

  const parsedDuration = parseDuration(duration);

  const seconds = parsedDuration?.seconds;
  if (seconds > 60) {
    parsedDuration.seconds = seconds % 60;
    parsedDuration.minutes = Math.floor(seconds / 60) + parsedDuration?.minutes;
  }

  const minutes = parsedDuration?.minutes;
  if (minutes > 60) {
    parsedDuration.minutes = minutes % 60;
    parsedDuration.hours = Math.floor(minutes / 60) + parsedDuration?.hours;
  }

  return formatISODuration(parsedDuration);
}

function GEMAGVLXMLAusstrahlungForm({id}) {
  const {gemagvl4_ausstrahlung} = useSelector(getGEMAGVLXMLAusstrahlungGetter)(id);
  let {
    datum_uhrzeit_bis,
    alt_datum_uhrzeit_bis,
    datum_uhrzeit_von,
    alt_datum_uhrzeit_von,
    sendedauer: gemagvl4Sendedauer,
  } = useSelector(getGEMAGVL4AusstrahlungGetter)(gemagvl4_ausstrahlung);

  useEntityObserver({type: 'gemagvlxml_ausstrahlung', id});
  useEntityObserver({type: 'gemagvl4_ausstrahlung', id: gemagvl4_ausstrahlung});

  const datum_uhrzeit_von_values = allowedValues(datum_uhrzeit_von, alt_datum_uhrzeit_von);
  const datum_uhrzeit_bis_values = allowedValues(datum_uhrzeit_bis, alt_datum_uhrzeit_bis);

  const {watch} = useFormContext();
  const cur_datum_uhrzeit_von = tryFormatISO(watch('datum_uhrzeit_von'));
  const cur_datum_uhrzeit_bis = tryFormatISO(watch('datum_uhrzeit_bis'));

  gemagvl4Sendedauer = normalizeDuration(gemagvl4Sendedauer);

  let computedSendedauer = null;
  if (cur_datum_uhrzeit_von && cur_datum_uhrzeit_bis) {
    const start = parseISO(cur_datum_uhrzeit_von);
    const end = parseISO(cur_datum_uhrzeit_bis);
    if (start <= end) {
      computedSendedauer = normalizeDuration(formatISODuration({seconds: differenceInSeconds(end, start)}));
    }
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <YupField name="datum_uhrzeit_von"/>
          {datum_uhrzeit_von_values?.length > 0 && !datum_uhrzeit_von_values.includes(cur_datum_uhrzeit_von) ? (
            <Alert severity="warning">
              Ausstrahlungsbeginn weicht von GEMAGVL4-Daten ab, denen zufolge die Ausstrahlung um
              {' '}
              {datum_uhrzeit_von_values?.join(' oder ')}
              {' '}
              startete.
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <YupField name="datum_uhrzeit_bis"/>
          {datum_uhrzeit_bis_values?.length > 0 && !datum_uhrzeit_bis_values.includes(cur_datum_uhrzeit_bis) ? (
            <Alert severity="warning">
              Ausstrahlungsende weicht von GEMAGVL4-Daten ab, denen zufolge die Ausstrahlung um
              {' '}
              {datum_uhrzeit_bis_values?.join(' oder ')}
              {' '}
              endete.
            </Alert>
          ) : null}
        </Grid>
        {computedSendedauer ? (
          <Grid item xs={12}>
            <div>
              Ausstrahlungsdauer: {formatDuration(parseISODuration(computedSendedauer), {locale: deLocale})}
            </div>
            {computedSendedauer !== gemagvl4Sendedauer && gemagvl4Sendedauer ? (
              <Alert severity="warning">
                Ausstrahlungsdauer weicht von GEMAGVL4-Sendedauer
                ab: {formatDuration(parseISODuration(gemagvl4Sendedauer), {locale: deLocale})}.
              </Alert>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </DialogContent>
  );
}

export default function GEMAGVLXMLAusstrahlungFormDialog({data, ...props}) {
  const selectedOrganization = useSelector(getSelectedOrganization);
  const entityApi = useEntityApi(GEMAGVLXML_AUSSTRAHLUNG);

  const {id} = data;
  const {can_delete: canDelete} = useSelector(getGEMAGVLXMLAusstrahlungGetter)(id);

  const saveAusstrahlung = async (validatedData) => {
    const {organization, id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_ausstrahlungen/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  const {deleteEntity, deletingUuids} = useEntityDeleter({
    entityType: 'gemagvlxml_ausstrahlungen',
    baseUrl: `/api/sendemeldung/organizations/${selectedOrganization?.id}/gemagvlxml_ausstrahlungen/`,
  });

  return (
    <SimpleYupFormDialog
      id={'edit-ausstrahlung'}
      title={data?.id ? "Ausstrahlung bearbeiten" : "Ausstrahlung anlegen"}
      open={!!data}
      data={data}
      submit={saveAusstrahlung}
      onDelete={
        // TODO: Implement suitable confirmation mechanism.
        // TODO: Track and display deletion status.
        canDelete && id ? (
          () => confirm("Ausstrahlung wirklich löschen?") && deleteEntity(id)
        ) : undefined
      }
      deleteCaption="Ausstrahlung löschen"
      isDeleting={deletingUuids?.has(id)}
      schema={GEMAGVLXML_AUSSTRAHLUNG_SCHEMA}
      {...props}
    >
      <GEMAGVLXMLAusstrahlungForm id={id}/>
    </SimpleYupFormDialog>
  );
}
