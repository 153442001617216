import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Link, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import {useSelector} from "react-redux";
import {getSelectedOrganization} from "src/features/dashboard";
import {PictureAsPdf} from "@material-ui/icons";
import {getSessionToken} from "src/features/session";

export default function DocumentsPage() {
  const {documents} = useSelector(getSelectedOrganization);
  const sessionToken = useSelector(getSessionToken);

  return (
    <DashboardLayout
      titlePrefix="Vertragsdokumente"
      selectedPage="documents"
    >
      <Box my={2}>
        <Typography variant="h4">
          Vertragsdokumente
        </Typography>
      </Box>

      {!documents?.length ? (
        <Alert variant="contained" severity="info">
          <AlertTitle>Keine Dokumente gefunden</AlertTitle>
          Es wurden noch keine vertragsbezogenen Dokumente hinterlegt.
        </Alert>
      ) : (
        <List>
          {documents?.map(({uuid, name, description}, i) => (
            <ListItem key={uuid || i}>
              <ListItemIcon>
                <PictureAsPdf/>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link href={`/api/teilnahme/documents/${uuid}/blob/?token=${sessionToken}`}>
                    {name}
                  </Link>
                }
                secondary={description}
              />
            </ListItem>
          ))}
        </List>
      )}
    </DashboardLayout>
  );
}
