import React, {useEffect, useState} from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Card, CardContent, Chip, Grid, IconButton, makeStyles, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useSelector} from "react-redux";
import {getErschieneneTonaufnahmeGetter, getMusikPersonGetter, getOrgMusicWorkGetter} from "src/features/entity";
import MusikTitel from "src/components/entities/musicwork/components/MusikTitel";
import {useEntityApi, useEntityObserver} from "src/features/entity/entity-hooks";
import {ORG_MUSIC_WORK} from "src/api/api-schemas";
import {getSelectedOrganization} from "src/features/dashboard";
import MusikPerson, {
  INTERPRET_LIST,
  URHEBER_LIST,
  VERLEGER_LIST
} from "src/components/entities/musicwork/components/MusikPerson";
import ErschieneneTonaufnahme from "src/components/entities/musicwork/components/ErschieneneTonaufnahme";
import {TodoTasks} from "src/components/entities/todotask/TodoTasks";
import Playlist from "src/components/entities/gemagvlxml/components/Playlist";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {navigate} from "gatsby";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {AlertTitle} from "@material-ui/lab";
import {MusicPersonForm} from "src/components/entities/musicwork/MusicPersonForm";
import {MusicProductionIDForm} from "src/components/entities/musicwork/MusicProductionIDForm";
import {MusicTitleForm} from "src/components/entities/musicwork/MusicTitleForm";
import {ErschieneneTonaufnahmeForm} from "src/components/entities/musicwork/ErschieneneTonaufnahmeForm";
import {MusicWorkForm} from "src/components/entities/musicwork/MusicWorkForm";
import {Add, Edit} from "@material-ui/icons";
import {BESETZUNG_KNZ, RECHT_KNZ} from "src/features/dashboard/dashboard-validation";
import {MusicNutzungForm} from "src/components/entities/musicwork/MusicNutzungForm";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";
import MusikProduktionIds from "src/components/entities/musicwork/components/MusikProduktionIds";
import MusikInterpreten from "src/components/entities/musicwork/components/MusikInterpreten";
import MusikUrheber from "src/components/entities/musicwork/components/MusikUrheber";
import MusikHerkunft from "src/components/entities/musicwork/components/MusikHerkunft";
import {Unshrinkable} from "src/packages/unshrinkable";
import NaturalTime from "src/packages/natural-time-view";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}


export default function MusicWorkPage({id, '*': subPage}) {
  useEntityObserver({type: 'org_music_work', id});

  const {id: organization} = useSelector(getSelectedOrganization);

  const getOrgMusicWork = useSelector(getOrgMusicWorkGetter);

  const tab = subPage === 'plays' ? 1 : 0;

  const handleChange = (event, newTab) => {
    if (newTab === 1 && subPage !== 'plays') {
      navigate(`/dashboard/archive/${id}/plays/`);
    } else if (newTab === 0 && subPage !== undefined) {
      navigate(`/dashboard/archive/${id}/`);
    }
  };

  const entityApi = useEntityApi(ORG_MUSIC_WORK);

  useEffect(async () => {
    if (!organization || !id) {
      return;
    }
    await entityApi.get(
      `/api/sendemeldung/organizations/${organization}/org_music_works/${id}/`,
    );
  }, [organization, id]);

  const classes = useStyles();

  const orgMusicWork = getOrgMusicWork(id);
  const {
    id: orgMusicWorkId,
    musik_titel,
    titel_sonstige,
    recht_knz,
    besetzung_knz,
    gattung,
    nutzung_art_knz,
    aufnahme_datum,
    prod_titel,
    prod_ort,
    musik_herkunft_knz,
    todo_tasks: todoTasks,
    stations: stationIds,
    is_silence,
    created_at,
    updated_at,
    is_tentative,
  } = orgMusicWork;

  const {
    erschienene_tonaufnahme: erschieneneTonaufnahmeId,
    musik_personen,
    musik_produktion_ids,
    ...orgMusicWorkData
  } = orgMusicWork;
  const erschienene_tonaufnahme = useSelector(getErschieneneTonaufnahmeGetter)(erschieneneTonaufnahmeId);

  const getMusikPerson = useSelector(getMusikPersonGetter);
  const personen = musik_personen?.map(getMusikPerson);

  const interpreten = personen?.filter(({musik_person_rolle_knz}) => INTERPRET_LIST.includes(musik_person_rolle_knz));
  const urheber = personen?.filter(({musik_person_rolle_knz}) => URHEBER_LIST.includes(musik_person_rolle_knz));
  const verleger = personen?.filter(({musik_person_rolle_knz}) => VERLEGER_LIST.includes(musik_person_rolle_knz));

  const [editMusicWork, setEditMusicWork] = useState(null);
  const [editMusicNutzung, setEditMusicNutzung] = useState(null);
  const [editTitle, setEditTitle] = useState(null);
  const [editPerson, setEditPerson] = useState(null);
  const [editProduktionId, setEditProduktionId] = useState(null);
  const [editErschieneneTonaufnahme, setEditErschieneneTonaufnahme] = useState(null);

  return (
    <DashboardLayout
      titlePrefix="Musikwerk"
      selectedPage="database"
    >
      <Box my={2}>
        <Typography variant="h4">
          Musikwerk-Details
        </Typography>
      </Box>

      {orgMusicWorkId ? (
        <>
          <Box mt={2}>
            <Grid container>
              <Grid container item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Titel
                      {' '}
                      <IconButton
                        aria-label="edit"
                        onClick={() => setEditMusicWork({
                          id,
                          organization,
                          musik_titel,
                          recht_knz,
                          besetzung_knz,
                          gattung,
                          is_silence,
                        })}
                        size="small"
                      >
                        <Edit/>
                      </IconButton>
                    </Typography>
                    <Typography variant="h5" component="h2">
                      <MusikTitel
                        id={orgMusicWorkId}
                      />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid container item xs={6}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Recht
                      {' '}
                      <IconButton
                        aria-label="edit"
                        onClick={() => setEditMusicWork({
                          id,
                          organization,
                          musik_titel,
                          recht_knz,
                          besetzung_knz,
                          gattung,
                          is_silence,
                        })}
                        size="small"
                      >
                        <Edit/>
                      </IconButton>
                    </Typography>
                    {RECHT_KNZ[recht_knz]}
                  </CardContent>
                </Card>

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Besetzung
                      {' '}
                      <IconButton
                        aria-label="edit"
                        onClick={() => setEditMusicWork({
                          id,
                          organization,
                          musik_titel,
                          recht_knz,
                          besetzung_knz,
                          gattung,
                          is_silence,
                        })}
                        size="small"
                      >
                        <Edit/>
                      </IconButton>
                    </Typography>
                    {BESETZUNG_KNZ[besetzung_knz]}
                  </CardContent>
                </Card>
              </Grid>

              <Grid container item xs={6}>
                {(created_at || updated_at) ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Status
                      </Typography>
                      {!is_tentative && updated_at ? (
                        <p>zuletzt geändert <Unshrinkable><NaturalTime date={updated_at} inSentence/></Unshrinkable></p>
                      ) : created_at ? (
                        <p>erstellt <Unshrinkable><NaturalTime date={created_at} inSentence/></Unshrinkable></p>
                      ) : null}
                    </CardContent>
                  </Card>
                ) : null}

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Gattung
                      {' '}
                      <IconButton
                        aria-label="edit"
                        onClick={() => setEditMusicWork({
                          id,
                          organization,
                          musik_titel,
                          recht_knz,
                          besetzung_knz,
                          gattung,
                          is_silence,
                        })}
                        size="small"
                      >
                        <Edit/>
                      </IconButton>
                    </Typography>
                    {gattung}
                  </CardContent>
                </Card>
              </Grid>

              <Grid container item xs={6}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Urheber
                      {' '}
                      <IconButton
                        aria-label="add"
                        onClick={() => setEditPerson({organization, org_music_work: id, musik_person_rolle_knz: 'K'})}
                        size="small"
                      >
                        <Add/>
                      </IconButton>
                    </Typography>
                    <MusikUrheber id={id}/>
                  </CardContent>
                </Card>

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Interpreten
                      {' '}
                      <IconButton
                        aria-label="add"
                        onClick={() => setEditPerson({organization, org_music_work: id, musik_person_rolle_knz: 'INT'})}
                        size="small"
                      >
                        <Add/>
                      </IconButton>
                    </Typography>
                    <MusikInterpreten id={id}/>
                  </CardContent>
                </Card>

                {verleger?.length > 0 ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Verleger
                        {' '}
                        <IconButton
                          aria-label="add"
                          onClick={() => setEditPerson({organization, org_music_work: id, musik_person_rolle_knz: 'V'})}
                          size="small"
                        >
                          <Add/>
                        </IconButton>
                      </Typography>
                      {verleger?.map(({id}, i) => (
                        <p key={id || i}>
                          <MusikPerson
                            id={id}
                            showRole
                            onEdit={setEditPerson}
                          />
                        </p>
                      ))}
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>

              <Grid container item xs={6}>
                {(aufnahme_datum || prod_titel || prod_ort || musik_herkunft_knz === 'LIVE') ? (
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Aufnahme
                        {' '}
                        <IconButton
                          aria-label="add"
                          onClick={() => setEditMusicNutzung({
                            id,
                            organization,
                            nutzung_art_knz,
                            aufnahme_datum,
                            prod_titel,
                            prod_ort,
                            musik_herkunft_knz
                          })}
                          size="small"
                        >
                          <Edit/>
                        </IconButton>
                      </Typography>
                      {aufnahme_datum ? (
                        <div>
                          {aufnahme_datum}
                          {' '}
                          <Chip label="Datum" size="small"/>
                        </div>
                      ) : musik_herkunft_knz === 'LIVE' ? (
                        <Chip
                          icon={<Add/>}
                          variant="outlined"
                          color="primary"
                          clickable
                          label="Aufnahmedatum ergänzen"
                          onClick={() => setEditMusicNutzung({
                            id,
                            organization,
                            nutzung_art_knz,
                            aufnahme_datum,
                            prod_titel,
                            prod_ort,
                            musik_herkunft_knz
                          })}
                        />
                      ) : null}
                      {prod_titel ? (
                        <div>
                          {prod_titel}
                          {' '}
                          <Chip label="Name der Produktion" size="small"/>
                        </div>
                      ) : null}
                      {prod_ort ? (
                        <div>
                          {prod_ort}
                          {' '}
                          <Chip label="Ort" size="small"/>
                        </div>
                      ) : null}
                    </CardContent>
                  </Card>
                ) : null}

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Musikherkunft
                      {' '}
                      <IconButton
                        aria-label="add"
                        onClick={() => setEditMusicNutzung({
                          id,
                          organization,
                          nutzung_art_knz,
                          aufnahme_datum,
                          prod_titel,
                          prod_ort,
                          musik_herkunft_knz
                        })}
                        size="small"
                      >
                        <Edit/>
                      </IconButton>
                    </Typography>
                    <MusikHerkunft id={id}/>
                  </CardContent>
                </Card>

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Erschienene Tonaufnahme
                      {' '}
                      <IconButton
                        aria-label="edit"
                        onClick={() => setEditErschieneneTonaufnahme({
                          id: erschieneneTonaufnahmeId,
                          ...erschienene_tonaufnahme
                        })}
                        size="small"
                      >
                        <Edit/>
                      </IconButton>
                    </Typography>
                    <ErschieneneTonaufnahme
                      id={erschieneneTonaufnahmeId}
                      full
                    />
                  </CardContent>
                </Card>

                <Card className={classes.root}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Produktion-IDs
                      {' '}
                      <IconButton
                        aria-label="add"
                        onClick={() => setEditProduktionId({organization, org_music_work: id})}
                        size="small"
                      >
                        <Add/>
                      </IconButton>
                    </Typography>
                    <MusikProduktionIds id={id}/>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <div className={classes.root}>
              <Paper>
                <AppBar position="static" color="default">
                  <Tabs
                    value={tab}
                    // onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <HrefComponent
                      component={Tab}
                      href={`/dashboard/archive/${id}/`}
                      label={(
                        <>
                          Zu erledigen
                        </>
                      )}
                      {...a11yProps(1)}
                    />
                    <HrefComponent
                      component={Tab}
                      href={`/dashboard/archive/${id}/plays/`}
                      label={(
                        <>
                          Ausstrahlungen
                        </>
                      )}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0}>
                  <TodoTasks ids={todoTasks} stationIds={stationIds}>
                    <Alert severity="info">
                      <AlertTitle>Nichts zu tun</AlertTitle>
                      Für dieses Musikwerk gibt es derzeit keine offenen Aufgaben.
                    </Alert>
                  </TodoTasks>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <Playlist
                    orgMusicWorkId={orgMusicWorkId}
                    loadingContent={(
                      <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                        Ausstrahlungen werden geladen...
                      </Alert>
                    )}
                    showReports
                  >
                    <Alert variant="filled" severity="info">
                      Diese Ansicht enthält keine Einträge.
                    </Alert>
                  </Playlist>
                </TabPanel>
              </Paper>
            </div>

          </Box>

          {editMusicWork ? (
            <MusicWorkForm
              data={editMusicWork}
              onClose={() => setEditMusicWork(null)}
            />
          ) : null}

          {editMusicNutzung ? (
            <MusicNutzungForm
              data={editMusicNutzung}
              onClose={() => setEditMusicNutzung(null)}
            />
          ) : null}

          {editTitle ? (
            <MusicTitleForm
              data={editTitle}
              onClose={() => setEditTitle(null)}
            />
          ) : null}

          {editPerson ? (
            <MusicPersonForm
              data={editPerson}
              onClose={() => setEditPerson(null)}
            />
          ) : null}

          {editProduktionId ? (
            <MusicProductionIDForm
              data={editProduktionId}
              onClose={() => setEditProduktionId(null)}
            />
          ) : null}

          {editErschieneneTonaufnahme ? (
            <ErschieneneTonaufnahmeForm
              data={editErschieneneTonaufnahme}
              onClose={() => setEditErschieneneTonaufnahme(null)}
            />
          ) : null}
        </>
      ) : null}
    </DashboardLayout>
  );
}
