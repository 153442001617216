import React from "react";
import {Box} from "@material-ui/core";
import {TodoTask} from "src/components/entities/todotask/TodoTask";
import {useSelector} from "react-redux";
import {getTodoTaskGetter} from "src/features/entity";

export const TodoTasks = React.memo(function ({ids, children, mt = 1, ...props}) {
  const getTodoTask = useSelector(getTodoTaskGetter);
  const criticalIds = ids?.filter((id) => getTodoTask(id)?.message?.severity === 'error');
  const warningIds = ids?.filter((id) => getTodoTask(id)?.message?.severity === 'warning');
  const remainingIds = ids?.filter((id) => !(getTodoTask(id)?.message?.severity === 'error' || getTodoTask(id)?.message?.severity === 'warning'));

  return (
    <>
      {ids?.length ? (
        [...criticalIds, ...warningIds, ...remainingIds].map((id, i) => (
          <Box key={id || i} mt={mt}>
            <TodoTask id={id} {...props}/>
          </Box>
        ))
      ) : children}
    </>
  );
});
