import {useSelector} from "react-redux";
import {
  getErschieneneTonaufnahmeGetter,
  getGEMAGVL4AusstrahlungGetter,
  getGEMAGVLXMLAusstrahlungGetter,
  getMusikProduktionIdGetter,
  getOrgMusicWorkGetter,
  getStationGetter,
  getTodoTaskGetter
} from "src/features/entity";
import {darken, lighten, makeStyles} from '@material-ui/core/styles';
import {Alert, AlertTitle, Skeleton} from "@material-ui/lab";
import React, {useState} from "react";
import {
  AccessTime,
  Add,
  Album,
  Assignment,
  Build,
  Delete,
  ExpandMore,
  FilterCenterFocus,
  HelpOutline,
  LabelImportant,
  MoreHoriz,
  QueueMusic,
  VolumeUp
} from "@material-ui/icons";
import ProgrammeChip from "src/components/entities/programme/ProgrammeChip";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@material-ui/core";
import {useEntityApi, useEntityDeleter, useEntityObserver} from "src/features/entity/entity-hooks";
import {getSelectedOrganization, getSelectedOrganizationId} from "src/features/dashboard";
import LieferantIDFormDialog from "src/components/entities/user/LieferantIDFormDialog";
import PlaylistItem from "src/components/entities/gemagvlxml/components/PlaylistItem";
import {MusicProductionIDForm} from "src/components/entities/musicwork/MusicProductionIDForm";
import {MusicPersonForm} from "src/components/entities/musicwork/MusicPersonForm";
import {MusicWorkForm} from "src/components/entities/musicwork/MusicWorkForm";
import {MusicNutzungForm} from "src/components/entities/musicwork/MusicNutzungForm";
import {ErschieneneTonaufnahmeForm} from "src/components/entities/musicwork/ErschieneneTonaufnahmeForm";
import {ProgressButton, ProgressIconButton} from "src/packages/react-hook-form-mui-form-fields/submit-button-view";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import GEMAGVL4ImportSettingsFormDialog from "src/components/entities/user/GEMAGVL4ImportSettingsFormDialog";
import {TODO_TASK} from "src/api/api-schemas";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MUSIK_PRODUKTION_ID_TYP_KNZ} from "src/features/dashboard/dashboard-validation";

const useStyles = makeStyles((theme) => {
  const getColor = theme.palette.type === 'light' ? darken : lighten;
  const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;

  return {
    todoTaskAccordion: ({severity}) => ({
      background: theme.palette?.[severity]?.light,
      //height: 'auto',
      //margin: 0,
      //marginBottom: theme.spacing(1),
      color: getColor(theme.palette?.[severity]?.main, 0.6),
      backgroundColor: getBackgroundColor(theme.palette?.[severity]?.main, 0.9),
      '& $icon': {
        color: theme.palette?.[severity]?.main,
      },
      '& .MuiAccordionSummary-root': {
        paddingLeft: 0,
      },
      '& .Mui-expanded': {
        '& > .MuiAlert-root': {
          paddingTop: 0,
          paddingBottom: 0,
        },
        marginBottom: theme.spacing(0.5),
      },
      '& .MuiAccordionDetails-root': {
        paddingTop: 0,
      },
      '& .MuiAlert-action': {
        alignItems: 'flex-start',
      },
    }),
    todoTaskWrapper: {
      position: 'relative',
    },
    deleteButtonWrapper: {
      // margin: theme.spacing(1),
      position: 'absolute',
      top: theme.spacing(0),
      right: theme.spacing(0),
      color: theme.palette.warning.main,
    },
    spacer: {
      flex: 1,
    },
  };
});

const GenericTodoTask = React.memo(function (
  {
    id,
    message,
    children,
    gemagvlxmlLieferungId,
    todo_type,
    hidePlaylistButton,
    hideMusicWorksButton,
    deleteTask,
    isDeletingTask,
    actions,
    is_ausstrahlung_related: isAusstrahlungRelated,
    is_music_work_related: isMusicRelated,
    affected_music_works_count: affectedMusicWorksCount,
    affected_ausstrahlungen_count: affectedAusstrahlungenCount,
    is_processing: isProcessing,
    suggested_quickfixes: suggestedQuickfixes,
    ...props
  }
) {
  const showMusicWorkButton = isMusicRelated && !hideMusicWorksButton && gemagvlxmlLieferungId;
  const showPlaylistButton = (isAusstrahlungRelated || (isMusicRelated && hideMusicWorksButton)) && !hidePlaylistButton && gemagvlxmlLieferungId && !showMusicWorkButton;

  const showIgnoreButton = (message?.severity === 'warning' || message?.severity === 'info') && deleteTask;
  let showSimpleIgnoreButton = showIgnoreButton;
  let multipleItemsAffected = false;

  if (deleteTask && gemagvlxmlLieferungId) {
    const originalDeleteTask = deleteTask;
    deleteTask = () => {
      confirm("Aufgabe inklusive Unteraufgaben löschen?") && originalDeleteTask();
    };
    showSimpleIgnoreButton = false;
    multipleItemsAffected = true;
  }

  const showMenu = showIgnoreButton && !showSimpleIgnoreButton;

  const [expanded, setExpanded] = useState(true);

  const classes = useStyles({severity: message?.severity || 'info'});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const hasSuggestions = message?.suggestions;
  const [showSuggestions, setShowSuggestions] = React.useState(null);

  const entityApi = useEntityApi(TODO_TASK);
  const organization = useSelector(getSelectedOrganizationId);
  const performQuickfix = async ({id, ...data}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/todo_tasks/${id}/quickfix/`,
      data,
    );
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} TransitionProps={{unmountOnExit: true}}
                 className={classes.todoTaskAccordion}>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <Alert severity={message?.severity || 'info'} icon={<Assignment/>} className={classes.todoTaskWrapper}>
            <AlertTitle>
              {message?.title || <Skeleton variant="text" width={200}/>}
            </AlertTitle>
          </Alert>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {children ? (
              <>
                <Typography gutterBottom>{message?.text}</Typography>
                {children}
              </>
            ) : (
              <Typography gutterBottom>{message?.text}</Typography>
            )}

            {message?.suggestions ? (
              <Collapse in={!!showSuggestions}>
                <Box mt={2}>
                  <Alert
                    variant="filled"
                    severity="info"
                    icon={<HelpOutline/>}
                    onClose={() => setShowSuggestions(false)}
                  >
                    {message?.suggestions?.length === 1 ? (
                      <>
                        <AlertTitle>Hilfestellung zu dieser Aufgabe</AlertTitle>
                        <Typography>{message?.suggestions[0]}</Typography>
                      </>
                    ) : (
                      <>
                        <AlertTitle>Hilfestellungen zu dieser Aufgabe</AlertTitle>
                        {message?.suggestions?.map((suggestion, i) => (
                          <Box mt={2} mb={2}>
                            <Alert key={i} variant="standard" severity="info" icon={<LabelImportant/>}>
                              <Typography>{suggestion}</Typography>
                            </Alert>
                          </Box>
                        ))}
                      </>
                    )}
                  </Alert>
                </Box>
              </Collapse>
            ) : null}

            {isProcessing ? (
              <Box mt={2}>
                <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                  <AlertTitle>
                    Bitte haben Sie einen Augenblick Geduld
                  </AlertTitle>
                  Wir sind gerade dabei, den von Ihnen ausgewählten Lösungsvorschlag umzusetzen.
                  Dies kann einige Minuten dauern.
                </Alert>
              </Box>
            ) : null}
          </div>
        </AccordionDetails>
        {(showMenu || showIgnoreButton || showPlaylistButton || showMusicWorkButton || actions || hasSuggestions) ? (<>
            <Divider/>
            <AccordionActions>
              {showMenu && !isDeletingTask ? (
                <>
                  <Tooltip title="weitere Aktionen">
                    <IconButton
                      aria-label="more actions"
                      aria-controls={`${id}-more-menu`}
                      aria-haspopup="true"
                      onClick={openMenu}
                    >
                      <MoreHoriz/>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id={`${id}-more-menu`}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={menuOpen}
                    onClose={closeMenu}
                  >
                    {showIgnoreButton ? (
                      <MenuItem onClick={deleteTask}>
                        <ListItemIcon>
                          <Delete fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                          {multipleItemsAffected ? "Hinweis für alle betroffenen Elemente löschen" : "Hinweis löschen"}
                        </ListItemText>
                      </MenuItem>
                    ) : null}
                  </Menu>
                </>
              ) : showIgnoreButton ? (
                <Tooltip
                  title={multipleItemsAffected ? "Hinweis für alle betroffenen Elemente löschen" : "Hinweis löschen"}>
              <span>
                <ProgressIconButton
                  onClick={deleteTask}
                  disabled={isDeletingTask}
                  inProgress={isDeletingTask}
                  color="action"
                >
                  <Delete/>
                </ProgressIconButton>
              </span>
                </Tooltip>
              ) : null}

              {hasSuggestions && !showSuggestions ? (
                <Tooltip title="Hilfestellungen anzeigen">
                  <IconButton
                    onClick={() => setShowSuggestions(true)}
                  >
                    <HelpOutline/>
                  </IconButton>
                </Tooltip>
              ) : null}

              <div style={{flex: 1}}/>

              {gemagvlxmlLieferungId ? (
                message?.severity === 'error' ? (
                  <>
                    <Tooltip
                      title="Diese Aufgabe muss zwingend bearbeitet werden, um eine Generierung von XML-Sendemeldungen technisch zu ermöglichen.">
                      <Chip
                        color="primary"
                        label="Bearbeitung zwingend erforderlich"
                      />
                    </Tooltip>
                    <div style={{flex: 1}}/>
                  </>
                ) : null
              ) : null}

              {showPlaylistButton ? (
                <HrefButton
                  startIcon={<QueueMusic/>}
                  color="primary"
                  href={`/dashboard/reports/${gemagvlxmlLieferungId}/entries/?todoTasks=true&todoTaskId=${id}`}
                >
                  {affectedAusstrahlungenCount === 1 ? (
                    <>einzige betroffene Ausstrahlung anzeigen</>
                  ) : affectedAusstrahlungenCount ? (
                    <>alle {affectedAusstrahlungenCount} betroffenen Ausstrahlungen anzeigen</>
                  ) : (
                    <>alle betroffenen Ausstrahlungen anzeigen</>
                  )}
                </HrefButton>
              ) : null}

              {showMusicWorkButton ? (
                <HrefButton
                  startIcon={<Album/>}
                  color="primary"
                  href={`/dashboard/reports/${gemagvlxmlLieferungId}/music/?todoTasks=true&todoTaskId=${id}`}
                >
                  {affectedMusicWorksCount === 1 ? (
                    <>einziges betroffenes Musikwerk anzeigen</>
                  ) : affectedMusicWorksCount ? (
                    <>alle {affectedMusicWorksCount} betroffenen Musikwerke anzeigen</>
                  ) : (
                    <>alle betroffenen Musikwerke anzeigen</>
                  )}
                </HrefButton>
              ) : null}

              {isProcessing ? (
                <>
                  <Tooltip title="Die Verarbeitung kann einige Minuten dauern.">
                    <div>
                      <ProgressButton
                        inProgress
                      >
                        wird verarbeitet...
                      </ProgressButton>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  {suggestedQuickfixes?.map(({type, caption, help_text, confirmation_text, data}) => {
                    let button = (
                      <Button
                        key={type}
                        startIcon={<Build/>}
                        label={caption}
                        clickable
                        color="primary"
                        onClick={(event) => {
                          event.stopPropagation();
                          if (!confirmation_text || confirm(confirmation_text)) {
                            performQuickfix({id, type, data});
                          }
                        }}
                      >
                        {caption}
                      </Button>
                    );
                    return help_text ? (
                      <Tooltip key={type} title={help_text}>
                        {button}
                      </Tooltip>
                    ) : button;
                  })}
                  {actions}
                </>
              )}

            </AccordionActions>
          </>
        ) : null}
      </Accordion>
    </>
  );
});

const NoLieferantIdTodoTask = React.memo(function (props) {
  const organization = useSelector(getSelectedOrganization);
  const [editLieferantID, setEditLieferantID] = useState(null);

  return (
    <GenericTodoTask
      {...props}
      actions={!organization?.gemagvlxml_lieferant_id ? (
        <>
          <Button
            startIcon={<Add/>}
            label="Lieferant-ID ergänzen"
            clickable
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              setEditLieferantID(organization);
            }}
          >
            Lieferant-ID ergänzen
          </Button>
        </>
      ) : null}
    >
      {editLieferantID ? (
        <LieferantIDFormDialog
          data={editLieferantID}
          onClose={() => setEditLieferantID(null)}
        />
      ) : null}
    </GenericTodoTask>
  );
});

const NoStationIdTodoTask = React.memo(function ({stationIds, ...props}) {
  const getStation = useSelector(getStationGetter);
  const stations = stationIds?.map(getStation);

  return (
    <GenericTodoTask {...props}>
      {stations?.filter(({gemagvl_sender_prg_id}) => !gemagvl_sender_prg_id).map(({id: stationId}, i) => (
        <ProgrammeChip key={stationId || i} id={stationId}/>
      ))}
    </GenericTodoTask>
  );
});

const GEMAGVL4AusstrahlungRelatedTodoTask = React.memo(function ({gemagvlxmlausstrahlungId, ...props}) {
  const getGEMAGVLXMLAusstrahlung = useSelector(getGEMAGVLXMLAusstrahlungGetter);
  const {gemagvl4_ausstrahlung: gemagvl4ausstrahlungId} = getGEMAGVLXMLAusstrahlung(gemagvlxmlausstrahlungId);

  const getGEMAGVL4Ausstrahlung = useSelector(getGEMAGVL4AusstrahlungGetter);
  const {lineno, original_line} = getGEMAGVL4Ausstrahlung(gemagvl4ausstrahlungId);

  useEntityObserver({type: 'gemagvl4_ausstrahlung', id: gemagvl4ausstrahlungId});

  return (
    <GenericTodoTask {...props}>
      {gemagvl4ausstrahlungId ? (
        <>
          <Typography component="h5">
            Betroffene Zeile (#{lineno})
          </Typography>
          <pre style={{whiteSpace: 'pre-wrap'}}>
            {original_line}
          </pre>
        </>
      ) : null}
    </GenericTodoTask>
  );
});

const OverlappingTimesTodoTask = React.memo(function ({metadata, gemagvlxmlausstrahlungId, ...props}) {
  const {conflicting_gemagvlxml_ausstrahlung_id: ausstrahlungId} = metadata || {};

  return (
    <GenericTodoTask {...props}>
      {ausstrahlungId ? (
        <Box mt={2}>
          <Typography gutterBottom>
            Erkannte Überlappung:
          </Typography>
          <PlaylistItem
            id={ausstrahlungId}
            statusText="überlappende Ausstrahlung"
            statusIcon={<AccessTime color="primary" fontSize="large"/>}
          />
          {gemagvlxmlausstrahlungId ? (
            <PlaylistItem
              id={gemagvlxmlausstrahlungId}
              previousId={ausstrahlungId}
              expanded={false}
              expansible={false}
              statusText="betrachtete Ausstrahlung"
              statusIcon={<FilterCenterFocus fontSize="large"/>}
            />
          ) : null}
        </Box>
      ) : null}
    </GenericTodoTask>
  );
});

const StationRelatedTodoTask = React.memo(function ({metadata, ...props}) {
  const {stations: stationIds} = metadata || {};

  return (
    <GenericTodoTask {...props}>
      {stationIds ? (
        <Box mt={2}>
          <Typography gutterBottom>
            Betroffene Programme:
            {' '}
            {stationIds?.map((stationId, i) => (
              <ProgrammeChip key={stationId || i} id={stationId}/>
            ))}
          </Typography>
        </Box>
      ) : null}
    </GenericTodoTask>
  );
});

const ProduktionIDTodoTask = React.memo(function ({metadata, ...props}) {
  const {musik_produktion_id: musikProduktionId} = metadata || {};

  const [editProduktionId, setEditProduktionId] = useState(null);

  const getMusikProduktionId = useSelector(getMusikProduktionIdGetter);
  const data = getMusikProduktionId(musikProduktionId);
  const {id_typ_knz} = data || {};
  const typ = MUSIK_PRODUKTION_ID_TYP_KNZ[id_typ_knz] || "Produktion-ID";

  return (
    <GenericTodoTask
      {...props}
      actions={data?.id ? (
        <>
          <Button
            color="primary"
            onClick={() => setEditProduktionId(data)}
          >
            {typ} korrigieren
          </Button>
          {editProduktionId ? (
            <MusicProductionIDForm
              data={editProduktionId}
              onClose={() => setEditProduktionId(null)}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MissingProdIdTodoTask = React.memo(function ({metadata, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editProduktionId, setEditProduktionId] = useState(null);

  const getOrgMusicWork = useSelector(getOrgMusicWorkGetter);
  const {organization, ...data} = getOrgMusicWork(orgMusicWorkId);

  return (
    <GenericTodoTask
      {...props}
      actions={data?.id ? (
        <>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditProduktionId({organization, org_music_work: orgMusicWorkId})}
          >
            Produktion-ID ergänzen
          </Button>
          {editProduktionId ? (
            <MusicProductionIDForm
              data={editProduktionId}
              onClose={() => setEditProduktionId(null)}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MissingUrheberTodoTask = React.memo(function ({metadata, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editPerson, setEditPerson] = useState(null);

  const getOrgMusicWork = useSelector(getOrgMusicWorkGetter);
  const {organization, ...data} = getOrgMusicWork(orgMusicWorkId);

  return (
    <GenericTodoTask
      {...props}
      actions={data?.id ? (
        <>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditPerson({org_music_work: orgMusicWorkId, organization, musik_person_rolle_knz: 'K'})}
          >
            Urheber ergänzen
          </Button>
          {editPerson ? (
            <MusicPersonForm
              data={editPerson}
              onClose={() => setEditPerson(null)}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MissingInterpretTodoTask = React.memo(function ({metadata, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editPerson, setEditPerson] = useState(null);

  const getOrgMusicWork = useSelector(getOrgMusicWorkGetter);
  const {organization, ...data} = getOrgMusicWork(orgMusicWorkId);

  return (
    <GenericTodoTask
      {...props}
      actions={data?.id ? (
        <>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditPerson({org_music_work: orgMusicWorkId, organization, musik_person_rolle_knz: 'INT'})}
          >
            Interpret ergänzen
          </Button>
          {editPerson ? (
            <MusicPersonForm
              data={editPerson}
              onClose={() => setEditPerson(null)}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MissingTitleTodoTask = React.memo(function ({metadata, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editMusicWork, setEditMusicWork] = useState(null);

  const getOrgMusicWork = useSelector(getOrgMusicWorkGetter);
  const {
    organization,
    musik_titel,
    recht_knz,
    besetzung_knz,
    gattung,
    is_silence,
    ...data
  } = getOrgMusicWork(orgMusicWorkId);

  return (
    <GenericTodoTask
      {...props}
      actions={data?.id ? (
        <>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditMusicWork({
              id: orgMusicWorkId,
              organization,
              musik_titel,
              recht_knz,
              besetzung_knz,
              gattung,
              is_silence,
            })}
          >
            Titel ergänzen
          </Button>
          {editMusicWork ? (
            <MusicWorkForm
              data={editMusicWork}
              onClose={() => setEditMusicWork(null)}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MusikNutzungTodoTask = React.memo(function ({metadata, actions, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editMusicNutzung, setEditMusicNutzung] = useState(null);

  const getOrgMusicWork = useSelector(getOrgMusicWorkGetter);
  const {
    organization,
    nutzung_art_knz,
    aufnahme_datum,
    prod_titel,
    prod_ort,
    musik_herkunft_knz,
    ...data
  } = getOrgMusicWork(orgMusicWorkId);

  return (
    <GenericTodoTask
      {...props}
      actions={data?.id ? (
        <>
          <Button
            color="primary"
            onClick={() => setEditMusicNutzung({
              id: orgMusicWorkId,
              organization,
              nutzung_art_knz,
              aufnahme_datum,
              prod_titel,
              prod_ort,
              musik_herkunft_knz,
            })}
          >
            Musiknutzung bearbeiten
          </Button>
          {editMusicNutzung ? (
            <MusicNutzungForm
              data={editMusicNutzung}
              onClose={() => setEditMusicNutzung(null)}
            />
          ) : null}
        </>
      ) : actions}
    />
  );
});

const MusikHerkunftTodoTask = React.memo(function ({metadata, ...props}) {
  const {
    affected_jingle_music_works_count: affectedJingleMusicWorksCount,
    affected_standard_music_works_count: affectedStandardMusicWorksCount,
  } = metadata || {};

  const [editImportSettings, setEditImportSettings] = useState(null);

  const organization = useSelector(getSelectedOrganization);
  const {jingle_musik_herkunft_knz} = organization;

  return (
    <MusikNutzungTodoTask
      metadata={metadata}
      {...props}
      actions={(
        (affectedJingleMusicWorksCount > 0 && !jingle_musik_herkunft_knz) ? (
          <>
            <Button
              color="primary"
              startIcon={<VolumeUp/>}
              onClick={() => setEditImportSettings(organization)}
            >
              Musikherkunft Ihrer Jingle-Meldungen festlegen
            </Button>
            {editImportSettings ? (
              <GEMAGVL4ImportSettingsFormDialog
                data={editImportSettings}
                onClose={() => setEditImportSettings(null)}
              />
            ) : null}
          </>
        ) : undefined
      )}
    />
  );
});

const ErschieneneTonaufnahmeTodoTask = React.memo(function ({metadata, ...props}) {
  let {erschienene_tonaufnahme_id: erschieneneTonaufnahmeId, org_music_work_id: orgMusicWorkId} = metadata || {};

  const getOrgMusicWork = useSelector(getOrgMusicWorkGetter);
  if (erschieneneTonaufnahmeId === undefined) {
    erschieneneTonaufnahmeId = getOrgMusicWork(orgMusicWorkId)?.erschienene_tonaufnahme;
  }

  const [editErschieneneTonaufnahme, setEditErschieneneTonaufnahme] = useState(null);

  const getErschieneneTonaufnahme = useSelector(getErschieneneTonaufnahmeGetter);
  const erschienene_tonaufnahme = getErschieneneTonaufnahme(erschieneneTonaufnahmeId);

  return (
    <GenericTodoTask
      {...props}
      actions={erschienene_tonaufnahme?.id ? (
        <>
          <Button
            startIcon={<Album/>}
            color="primary"
            onClick={() => setEditErschieneneTonaufnahme(erschienene_tonaufnahme)}
          >
            Erschienene Tonaufnahme bearbeiten
          </Button>
          {editErschieneneTonaufnahme ? (
            <ErschieneneTonaufnahmeForm
              data={editErschieneneTonaufnahme}
              onClose={() => setEditErschieneneTonaufnahme(null)}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const TODO_TASK_COMPONENTS = {
  no_station_id: NoStationIdTodoTask,
  no_lieferant_id: NoLieferantIdTodoTask,
  gemagvl4syntax: GEMAGVL4AusstrahlungRelatedTodoTask,
  gemagvl4linelen: GEMAGVL4AusstrahlungRelatedTodoTask,
  overlappingtime: OverlappingTimesTodoTask,
  missingjingles: StationRelatedTodoTask,
  unexpectedjingle: StationRelatedTodoTask,
  invalidprodid: ProduktionIDTodoTask,
  missingprodid: MissingProdIdTodoTask,
  invalidurheber: MissingUrheberTodoTask,
  invalidinterpret: MissingInterpretTodoTask,
  invalidtitle: MissingTitleTodoTask,
  aufnahmedatum: MusikNutzungTodoTask,
  invalidduration: MusikNutzungTodoTask,
  missingherkunft: MusikHerkunftTodoTask,
  missingatvdosi: ErschieneneTonaufnahmeTodoTask,
  missinglc: ErschieneneTonaufnahmeTodoTask,
  missinglabel: ErschieneneTonaufnahmeTodoTask,
  missingvoedatum: ErschieneneTonaufnahmeTodoTask,
  erschtonaufnahme: ErschieneneTonaufnahmeTodoTask,
};

export const TodoTask = React.memo(function ({id, ...props}) {
  useEntityObserver({type: 'todo_task', id});

  const organization = useSelector(getSelectedOrganizationId);

  const todoTask = useSelector(getTodoTaskGetter)(id);
  const {id: todoTaskId, todo_type: todoType, isDeleted} = todoTask;

  const {deleteEntity, deletingUuids} = useEntityDeleter({
    entityType: 'todo_tasks',
    baseUrl: `/api/sendemeldung/organizations/${organization}/todo_tasks/`,
  });

  let TodoTaskComponent = TODO_TASK_COMPONENTS[todoType] || GenericTodoTask;

  return (
    todoTaskId ? (
      <TodoTaskComponent
        {...todoTask}
        deleteTask={organization ? () => deleteEntity(todoTaskId) : undefined}
        isDeletingTask={deletingUuids?.has(todoTaskId)}
        {...props}
      />
    ) : isDeleted ? (
      <GenericTodoTask
        message={{
          title: "Erledigt",
          text: "Diese Aufgabe wurde bereits erledigt oder gelöscht.",
          severity: 'info',
        }}
      >
      </GenericTodoTask>
    ) : (
      <Skeleton variant="text"/>
    )
  );
});
