import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {ORGANIZATION_DETAILS_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {ORGANIZATION} from "src/api/api-schemas";
import _ from 'lodash';

export default function GEMAGVL4ImportSettingsFormDialog({data, ...props}) {
  const entityApi = useEntityApi(ORGANIZATION);

  let {id, gemagvl4_encoding_preferences, gemagvl4_timezone, jingle_musik_herkunft_knz} = data || {};

  const saveOrganization = async (validatedData) => {
    const {id} = validatedData;
    await entityApi.patch(
      `/api/sendemeldung/organizations/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  // Convert encoding representation to string (this is a hack as we only implement a fraction of the backend's
  // capabilities at this point).
  if (_.isArray(gemagvl4_encoding_preferences) && gemagvl4_encoding_preferences?.length === 1) {
    gemagvl4_encoding_preferences = gemagvl4_encoding_preferences[0];
  } else if (gemagvl4_encoding_preferences === null) {
    gemagvl4_encoding_preferences = 'auto';
  } else {
    gemagvl4_encoding_preferences = undefined;
  }

  if (gemagvl4_timezone === null) {
    gemagvl4_timezone = 'Europe/Berlin';
  }

  return (
    <SimpleYupFormDialog
      title={"GEMAGVL4-Import-Einstellungen bearbeiten"}
      open={!!data}
      data={{id, gemagvl4_encoding_preferences, gemagvl4_timezone, jingle_musik_herkunft_knz}}
      submit={saveOrganization}
      schema={ORGANIZATION_DETAILS_SCHEMA}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YupField name="gemagvl4_encoding_preferences"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="gemagvl4_timezone"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="jingle_musik_herkunft_knz"/>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
