import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {getGEMAGVL4LieferungGetter, getGEMAGVLXMLLieferungGetter, getTodoTaskGetter} from "src/features/entity";
import {useEntityApi, useEntityObserver} from "src/features/entity/entity-hooks";
import {GEMAGVLXML_LIEFERUNG} from "src/api/api-schemas";
import Alert from "@material-ui/lab/Alert";
import {
  ArrowDropDown,
  AttachFile,
  Backup,
  Check,
  Close,
  Delete,
  Drafts,
  Email,
  ExpandMore,
  GetApp,
  HourglassEmpty,
  InfoOutlined,
  MoreHoriz,
  Search,
  Warning
} from "@material-ui/icons";
import {AlertTitle} from "@material-ui/lab";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Collapse,
  Divider,
  Grid,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip
} from "@material-ui/core";
import ProgrammeChip from "src/components/entities/programme/ProgrammeChip";
import Typography from "@material-ui/core/Typography";
import dateFormat from "dateformat";
import {useSessionTokenUrl} from "src/api/api-hooks";
import UploadedFileRow from "src/components/entities/upload/UploadedFileRow";
import CircularProgressWithLabel from "src/components/core/CircularProgressWithLabel";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import HrefChip from "src/packages/gatsby-mui-helpers/HrefChip";
import HrefIconButton from "src/packages/gatsby-mui-helpers/HrefIconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  icon: {
    flex: 0,
    paddingRight: theme.spacing(2),
    minWidth: 48,
    color: theme.palette.action.active,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50.0%',
    flexShrink: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    //flexBasis: '100.0%',
    //flexShrink: 0,
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  fileHash: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    '& > span': {
      verticalAlign: 'middle',
      display: 'inline-block',
      maxWidth: '3.5rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontFamily: 'monospace',
      fontSize: '0.9rem',
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading2: {
    paddingLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandedAccordion: {
    // background: theme.palette.action.hover,
  },
}));

export function GemaGVLXMLGenerateButton({id, ...props}) {
  useEntityObserver({type: 'gemagvlxml_lieferung', id});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    id: gemaGvlXmlLieferungId,
    organization,
    can_publish: canPublish,
    is_pre_approval_report: isPreApprovalReport,
  } = getGEMAGVLXMLLieferung(id);


  const entityApi = useEntityApi(GEMAGVLXML_LIEFERUNG);

  const generate = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/export/`,
    );
  };

  const publish = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/publish/`,
    );
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedItem, setSelectedItem] = React.useState('publish');

  const handleMenuItemClick = (event, item) => {
    setSelectedItem(item);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (!canPublish) {
    return (
      <Button
        color="primary"
        {...props}
        onClick={() => generate({organization, gemagvlxml_lieferung: gemaGvlXmlLieferungId})}
      >
        GEMAGVL-XML-Sendemeldung generieren
      </Button>
    );
  }

  return (
    <div style={{zIndex: 1000}}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup variant='text' color="primary" ref={anchorRef} aria-label="split button" {...props}>
            {selectedItem === 'publish' ? (
              (!isPreApprovalReport && canPublish) ? (
                <Tooltip
                  title="Hiermit werden die XML-Meldungen an GEMA und GVL übermittelt."
                >
                  <Button
                    onClick={() => confirm("Lieferung jetzt an GEMA und GVL übermitteln?") && publish({
                      organization,
                      gemagvlxml_lieferung: gemaGvlXmlLieferungId
                    })}
                    color="primary"
                    startIcon={<Backup/>}
                  >
                    an GEMA/GVL übermitteln
                  </Button>
                </Tooltip>
              ) : (isPreApprovalReport && canPublish) ? (
                <Tooltip
                  title="Hiermit werden die XML-Meldungen zur Prüfung im Rahmen der Testwochen an GEMA und GVL übermittelt."
                >
                  <Button
                    onClick={() => confirm("Lieferung jetzt zur Überprüfung durch GEMA / GVL im Rahmen der Testwochen freigeben?") && publish({
                      organization,
                      gemagvlxml_lieferung: gemaGvlXmlLieferungId
                    })}
                    color="primary"
                    startIcon={<Backup/>}
                  >
                    zur Überprüfung an GEMA/GVL übermitteln
                  </Button>
                </Tooltip>
              ) : null
            ) : (
              <Button
                color="primary"
                onClick={() => generate({organization, gemagvlxml_lieferung: gemaGvlXmlLieferungId})}
              >
                GEMAGVL-XML-Sendemeldung generieren
              </Button>
            )}
            <Button
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDown/>
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        selected={selectedItem === 'publish'}
                        onClick={(event) => handleMenuItemClick(event, 'publish')}
                      >
                        An GEMA/GVL übermitteln
                      </MenuItem>
                      <MenuItem
                        selected={selectedItem === 'export'}
                        onClick={(event) => handleMenuItemClick(event, 'export')}
                      >
                        XML-Sendemeldung nur generieren
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </div>
  );
}

export default function GemaGVLXMLRow(
  {
    id,
    expanded = false,
    defaultExpanded,
    expandedUuid,
    expansible,
    onChange,
    primaryActions,
    hideDetailLink,
    ...props
  },
) {
  const classes = useStyles();

  useEntityObserver({type: 'gemagvlxml_lieferung', id});

  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded !== undefined ? defaultExpanded : expanded);
  const simpleOnChange = useCallback((event, changedExpanded) => {
    setIsExpanded(changedExpanded);
  }, [setIsExpanded]);

  if (onChange) {
    expansible = true;
  }

  expanded = expanded || isExpanded;
  onChange = onChange || simpleOnChange;

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {
    id: gemaGvlXmlLieferungId,
    organization,
    status,
    datum_von,
    datum_bis,
    stations,
    error_messages,
    warning_messages,
    gemagvl4_lieferung,
    ausstrahlungen_count,
    upload,
    isDeleted,
    is_completed: isCompleted,
    is_failed: isFailed,
    is_processing: isProcessing,
    is_processing_started: isProcessingStarted,
    is_processing_queued: isProcessingQueued,
    is_processing_failed: isProcessingFailed,
    processing_progress: processingProgress,
    todo_tasks: todoTasks,
    can_delete: canDelete,
    can_publish: canPublish,
    is_pre_approval_report: isPreApprovalReport,
  } = getGEMAGVLXMLLieferung(id);

  // When GEMAGVLXMLLieferung is in import status, we additionally need the GEMAGVL4Lieferung.
  let observeGemaGVL4LieferungId = gemagvl4_lieferung;
  if (status !== -100) {
    observeGemaGVL4LieferungId = null;
  }
  useEntityObserver({type: 'gemagvl4_lieferung', id: observeGemaGVL4LieferungId});

  const getGEMAGVL4Lieferung = useSelector(getGEMAGVL4LieferungGetter);
  let {
    status: status4,
    // is_processing: isProcessing4,
    is_processing_queued: isProcessingQueued4,
  } = getGEMAGVL4Lieferung(gemagvl4_lieferung);

  const isImporting = (status === -100 && status4 === 100);

  if (expandedUuid !== undefined && expandedUuid === gemagvl4_lieferung) {
    expanded = true;
  }

  const entityApi = useEntityApi(GEMAGVLXML_LIEFERUNG);

  const doDelete = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/delete/`,
    );
  };

  const publish = async ({organization, gemagvlxml_lieferung}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/publish/`,
    );
  };

  let downloadUrl = null;
  const downloadUrlCandidate = useSessionTokenUrl(`/api/sendemeldung/organizations/${organization}/gemagvlxml_lieferungen/${gemaGvlXmlLieferungId}/blob/`);
  if (status === 200 || status === 307) {
    downloadUrl = downloadUrlCandidate;
  }

  let serverStatus = (
    processingProgress?.status_text ? (
      processingProgress?.status_text
    ) : isProcessingQueued ? (
      "Warte auf Verarbeitung..."
    ) : isProcessingStarted ? (
      "wird verarbeitet..."
    ) : status === 200 ? (
      "XML-Meldungen generiert."
    ) : status === 500 && !error_messages?.length ? (
      "Angaben unvollständig."
    ) : status === 501 ? (
      "Import fehlgeschlagen."
    ) : (status === 500 || isProcessingFailed) ? (
      "Ein Fehler ist aufgetreten."
    ) : status >= 100 && status < 200 ? (
      "XML-Meldungen werden generiert..."
    ) : status === -100 ? (
      "wird aufbereitet..."
    ) : status === 307 && isPreApprovalReport ? (
      "Freigegeben zur Überprüfung durch GEMA / GVL."
    ) : status === 307 && !isPreApprovalReport ? (
      "Freigegeben für GEMA / GVL."
    ) : null
  );

  let serverProgress = undefined;
  if (processingProgress?.total) {
    serverProgress = processingProgress?.progress / processingProgress?.total;
  }

  const getTodoTask = useSelector(getTodoTaskGetter);
  const hasCriticalTodoTasks = todoTasks?.map(getTodoTask).filter(
    ({message}) => message?.severity === 'error',
  )?.length > 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const [showUpload, setShowUpload] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  if (isDeleted) {
    return (
      <Box mt={2} mb={2}>
        <Alert variant="filled" color="error" severity="success">
          Diese Lieferung wurde gelöscht.
        </Alert>
      </Box>
    );
  }

  const potentialDelayInfo = (
    <p>
      Sie müssen nicht warten! Die Verarbeitung erfolgt automatisch, auch wenn Sie diese Webseite schließen. Das
      Ergebnis ist dann abrufbar, wenn Sie sich das nächste mal einloggen.
    </p>
  );

  return (
    <Accordion expanded={expanded} onChange={expansible ? onChange : null} TransitionProps={{unmountOnExit: true}}
               className={expanded ? classes.expandedAccordion : null} {...props}>
      <AccordionSummary
        expandIcon={expansible ? (<ExpandMore/>) : null}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Grid container style={{alignItems: 'center'}}>
          <Grid item container xs={6} style={{alignItems: 'center'}}>
            <div className={classes.icon}>
              {(status === 307) ? (
                <Email color="action" fontSize="large"/>
              ) : (
                <Drafts color="action" fontSize="large"/>
              )}
            </div>
            <div className={classes.heading2}>
              <Typography className={classes.heading2}>
                {datum_von && datum_bis ? (
                  <>
                    {dateFormat(datum_von, "dd.mm.yyyy")}
                    {' '}&ndash;{' '}
                    {isImporting ? 'min. ' : null}{dateFormat(datum_bis, "dd.mm.yyyy")}
                  </>
                ) : null}
                {ausstrahlungen_count ? (
                  <Typography variant="inherit" className={classes.secondaryHeading2}>
                    {isImporting ? '≥ ' : null}{ausstrahlungen_count} Ausstrahlungen
                  </Typography>
                ) : null}
                {isPreApprovalReport ? (
                  <Typography variant="inherit" className={classes.secondaryHeading2}>
                    <Tooltip
                      title={(
                        <>
                          Bevor die Verwertungsgesellschaften Produktivmeldungen im XML-Format annehmen, muss zunächst
                          eine Beispiel-Sendemeldung bereitgestellt werden.
                          Diese wird von GEMA / GVL technisch überprüft; im Erfolgsfall erhält Ihr Sendeunternehmen
                          die entsprechende Freigabe.
                        </>
                      )}
                    >
                      <Chip label="Testlieferung"/>
                    </Tooltip>
                  </Typography>
                ) : null}
              </Typography>
              <Typography variant="inherit" className={classes.secondaryHeading}>
                {stations?.map((stationId, i) => (
                  <React.Fragment key={stationId || i}>
                    <ProgrammeChip id={stationId}/>
                    {' '}
                  </React.Fragment>
                ))}
              </Typography>
            </div>
          </Grid>
          <Grid item container xs={6} style={{alignItems: 'center'}}>
            <div className={classes.icon}>
              {isCompleted && canPublish ? (
                <AttachFile color="action" fontSize="large"/>
              ) : isCompleted ? (
                <Check color="action" fontSize="large"/>
              ) : isFailed ? (
                <Warning color="primary" fontSize="large"/>
              ) : isProcessing ? (
                <CircularProgressWithLabel size="2rem" color="inherit" value={serverProgress}/>
              ) : !hideDetailLink ? (
                <HourglassEmpty color="action" fontSize="large"/>
              ) : null}
            </div>
            <div className={classes.heading2}>
              <Typography variant="inherit" className={classes.secondaryHeading}>
                {(status === 0 || (status === 200 && canPublish)) && !hideDetailLink ? (
                  <>
                    <HrefChip
                      label="Jetzt prüfen und bearbeiten"
                      variant="outlined"
                      clickable
                      color="primary"
                      href={`/dashboard/reports/${gemaGvlXmlLieferungId}/`}
                    />
                  </>
                ) : serverStatus}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          {status === -100 ? (
            (status4 === 100 ? (
              isProcessingQueued4 ? (
                <Box mb={2}>
                  <Alert severity="info">
                    <AlertTitle>Warte auf Import...</AlertTitle>
                    <p>Wir starten den Import Ihrer Sendemeldungen, sobald alle vorherigen Aufgaben abgeschlossen
                      sind.</p>
                    {potentialDelayInfo}
                  </Alert>
                </Box>
              ) : (
                <Box mb={2}>
                  <Alert severity="info">
                    <AlertTitle>Import läuft</AlertTitle>
                    <p>Wir importieren Ihre Sendemeldungen gerade aus Ihrer hochgeladenen Datei.</p>
                    {potentialDelayInfo}
                  </Alert>
                </Box>
              )
            ) : (
              isProcessingQueued4 ? (
                <Box mb={2}>
                  <Alert severity="info">
                    <AlertTitle>Warte auf Weiterverarbeitung...</AlertTitle>
                    <p>
                      Wir bereiten Ihre Sendemeldungen in Kürze für die weitere Bearbeitung vor.
                      Dies geschieht, sobald alle vorherigen Aufgaben abgeschlossen sind.
                    </p>
                    {potentialDelayInfo}
                  </Alert>
                </Box>
              ) : (
                <Box mb={2}>
                  <Alert severity="info">
                    <AlertTitle>Aufbereitung läuft</AlertTitle>
                    <p>
                      Wir führen Ihre Sendemeldungen gerade mit Ihrer Musikdatenbank zusammen und bereiten sie für die
                      weitere Bearbeitung vor.
                    </p>
                    {potentialDelayInfo}
                  </Alert>
                </Box>
              )
            ))
          ) : null}

          {status >= 100 && status < 200 ? (
            <Box mb={2}>
              <Alert severity="info">
                <AlertTitle>Verarbeitung läuft</AlertTitle>
                Wir generieren gerade Ihre XML-Meldungen. Bitte haben Sie einen Augenblick Geduld.
              </Alert>
            </Box>
          ) : null}

          {status === 1 ? (
            <Box mb={2}>
              <Alert severity="info">
                <AlertTitle>In Löschung</AlertTitle>
                Ihre Sendemeldungen werden wunschgemäß gelöscht, sodass Sie die GEMAGVL4-Datei &ndash; ggf. mit
                Korrekturen &ndash; im Anschluss erneut hochladen können.
              </Alert>
            </Box>
          ) : null}

          {status === 200 ? (
            <Box mb={2}>
              <Alert variant="filled" severity="info">
                <AlertTitle>XML-Meldungen erfolgreich generiert</AlertTitle>
                Ihre XML-Meldungen stehen jetzt zum Download bereit.
                Sie können diese nun an die Verwertungsgesellschaften übermitteln.
              </Alert>
            </Box>
          ) : null}

          {status === 0 ? (
            <Box mb={2}>
              <Alert variant="filled" severity="info">
                <AlertTitle>Ihre XML-Meldungen sind vorbereitet</AlertTitle>
                Wir haben Ihre XML-Meldungen bereits vorbereitet.
                Sie können diese nun prüfen, bearbeiten und übermitteln.
              </Alert>
            </Box>
          ) : null}

          {status === 307 && isPreApprovalReport ? (
            <Box mb={2}>
              <Alert variant="filled" severity="info">
                <AlertTitle>Ihre XML-Meldungen wurden zur Prüfung durch GEMA und GVL freigegeben.</AlertTitle>
                Die Verwertungsgesellschaften haben nun Gelegenheit, Ihre XML-Meldungen zu überprüfen.
                Wir informieren Sie, sobald eine Rückmeldung vorliegt.
              </Alert>
            </Box>
          ) : null}

          {status === 307 && !isPreApprovalReport ? (
            <Box mb={2}>
              <Alert variant="filled" severity="info">
                <AlertTitle>Ihre XML-Meldungen wurden für GEMA und GVL freigegeben.</AlertTitle>
                <p>Die Verwertungsgesellschaften können Ihre XML-Meldungen nun weiterverarbeiten.</p>
                <p>Sollte es Rückfragen geben, werden wir Sie hierüber per E-Mail informieren.</p>
              </Alert>
            </Box>
          ) : null}

          {status === 500 && !error_messages?.length ? (
            <Box mb={2}>
              <Alert variant="filled" severity="warning">
                <AlertTitle>Bitte ergänzen Sie die fehlenden Angaben.</AlertTitle>
                Die Generierung der XML-Meldung konnte aufgrund fehlender Angaben nicht spezifikationskonform
                durchgeführt werden.
                Bitte ergänzen Sie die ggf. fehlenden Angaben und starten Sie die Generierung anschließend erneut.
              </Alert>
            </Box>
          ) : null}

          {error_messages?.map(({title, message}, i) => (
            <Box mb={2} key={i}>
              <Alert variant="filled" severity="error">
                <AlertTitle>{title}</AlertTitle>
                {message}
              </Alert>
            </Box>
          ))}
          {warning_messages?.map(({title, message}, i) => (
            <Box mb={2} key={i}>
              <Alert variant="filled" severity="warning">
                <AlertTitle>{title}</AlertTitle>
                {message}
              </Alert>
            </Box>
          ))}

          {upload ? (
            <Collapse in={showUpload}>
              <Box mt={4}>
                <Typography component="h6">
                  Ursprung
                  {' '}
                  <Tooltip title="Details verbergen">
                    <IconButton
                      onClick={() => setShowUpload(false)}
                      size="small"
                    >
                      <Close/>
                    </IconButton>
                  </Tooltip>
                </Typography>
                <p>Die Sendemeldungen stammen aus einer hochgeladenen Datei.</p>
                {showUpload ? (
                  <UploadedFileRow id={upload} expansible variant="outlined" hideReportLinks/>
                ) : null}
              </Box>
            </Collapse>
          ) : null}
        </div>
      </AccordionDetails>
      <Collapse
        in={(upload && !showUpload) || canDelete || canPublish || primaryActions || downloadUrl || !hideDetailLink || ((status === 0 || (status === 500)) && !hasCriticalTodoTasks)}>
        <Divider/>
        <AccordionActions>
          {canDelete ? (
            <>
              <Tooltip title="weitere Aktionen">
                <IconButton
                  aria-label="more actions"
                  aria-controls={`${id}-more-menu`}
                  aria-haspopup="true"
                  onClick={openMenu}
                >
                  <MoreHoriz/>
                </IconButton>
              </Tooltip>
              <Menu
                id={`${id}-more-menu`}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={menuOpen}
                onClose={closeMenu}
              >
                {canDelete ? (
                  <MenuItem onClick={() => confirm("Lieferung wirklich löschen?") && doDelete({
                    organization,
                    gemagvlxml_lieferung: gemaGvlXmlLieferungId
                  })}>
                    <ListItemIcon>
                      <Delete fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>
                      Lieferung löschen
                    </ListItemText>
                  </MenuItem>
                ) : null}
              </Menu>
            </>
          ) : null}
          {!hideDetailLink ? (
            <Tooltip title="Sendemeldungen anzeigen">
              <HrefIconButton
                href={`/dashboard/reports/${gemaGvlXmlLieferungId}/`}
              >
                <Search/>
              </HrefIconButton>
            </Tooltip>
          ) : (upload && !showUpload) ? (
            <Tooltip title="Details anzeigen">
              <IconButton
                onClick={() => setShowUpload(true)}
              >
                <InfoOutlined/>
              </IconButton>
            </Tooltip>
          ) : null}
          {downloadUrl ? (
            <Tooltip title="XML-Meldungen herunterladen">
              <IconButton
                href={downloadUrl}
              >
                <GetApp/>
              </IconButton>
            </Tooltip>
          ) : null}
          <div style={{flex: 1}}/>
          {primaryActions ? primaryActions : downloadUrl ? (
            <>
              {!isPreApprovalReport && canPublish ? (
                <Tooltip
                  title="Hiermit werden die XML-Meldungen an GEMA und GVL übermittelt."
                >
                  <Button
                    onClick={() => confirm("Lieferung jetzt an GEMA und GVL übermitteln?") && publish({
                      organization,
                      gemagvlxml_lieferung: gemaGvlXmlLieferungId
                    })}
                    color="primary"
                    startIcon={<Backup/>}
                  >
                    an GEMA/GVL übermitteln
                  </Button>
                </Tooltip>
              ) : (isPreApprovalReport && canPublish) ? (
                <Tooltip
                  title="Hiermit werden die XML-Meldungen zur Prüfung im Rahmen der Testwochen an GEMA und GVL übermittelt."
                >
                  <Button
                    onClick={() => confirm("Lieferung jetzt zur Überprüfung durch GEMA / GVL im Rahmen der Testwochen freigeben?") && publish({
                      organization,
                      gemagvlxml_lieferung: gemaGvlXmlLieferungId
                    })}
                    color="primary"
                    startIcon={<Backup/>}
                  >
                    zur Überprüfung an GEMA/GVL übermitteln
                  </Button>
                </Tooltip>
              ) : (isPreApprovalReport && status === 200) ? (
                <Tooltip
                  title="Im Rahmen der Testwochen soll nur eine XML-Meldung je Sendeunternehmen an GEMA / GVL übermittelt werden."
                >
                  <div>
                    <Button
                      color="primary"
                      startIcon={<Backup/>}
                      disabled
                    >
                      zur Überprüfung an GEMA/GVL übermitteln
                    </Button>
                  </div>
                </Tooltip>
              ) : null}
            </>
          ) : (status >= 0 || status === -100) && (status !== 307) ? (
            !hideDetailLink ? (
              <HrefButton
                color="primary"
                href={`/dashboard/reports/${gemaGvlXmlLieferungId}/`}
              >
                Prüfen &amp; bearbeiten
              </HrefButton>
            ) : (status === 0 || (status === 500)) && !hasCriticalTodoTasks ? (
              <GemaGVLXMLGenerateButton id={id}/>
            ) : (
              <></>
            )
          ) : null}
        </AccordionActions>
      </Collapse>
    </Accordion>
  );
}
