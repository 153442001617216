import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {getListing, getVisibleIdsForListing, updateListing} from "src/features/ui/listing";
import React, {useEffect, useState} from "react";
import {Pagination} from "src/components/paginator";
import MusicWorkRow from "src/components/entities/musicwork/MusicWorkRow";
import {useQuery} from "src/packages/route-utils";
import {navigate} from "@reach/router";
import {MusicWorkForm} from "src/components/entities/musicwork/MusicWorkForm";
import {getTodoTaskGetter} from "src/features/entity";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {TodoTask} from "src/components/entities/todotask/TodoTask";

function OrderableTableHeaderCell({ordering, updateOrdering, columnName, children}) {
  return (
    <TableCell
      onClick={() => {
        if (ordering[0] === columnName) {
          updateOrdering([`-${columnName}`]);
        } else {
          updateOrdering([columnName]);
        }
      }}
      style={{cursor: 'pointer'}}
    >
      {children}
      {' '}
      {ordering[0] === columnName ? (
        <KeyboardArrowUp color="action" size="small" style={{verticalAlign: 'middle'}}/>
      ) : (ordering[0] === `-${columnName}`) ? (
        <KeyboardArrowDown color="action" size="small" style={{verticalAlign: 'middle'}}/>
      ) : null}
    </TableCell>
  );
}

export default function MusicWorkTable(
  {
    organization,
    listingId,
    meta,
    children,
    noEntriesContent,
    loadingContent,
    gemagvlxmlLieferungId,
    totalCount,
    forcePagination,
  }
) {
  const dispatch = useDispatch();

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);
  const organizationId = organization || selectedOrganizationId;
  listingId = listingId && `${organizationId}/${listingId}`;

  const listing = useSelector(getListing)(listingId);
  const {count, meta: listingMeta} = listing;

  let queryPageSize = useQuery('pageSize');
  try {
    queryPageSize = parseInt(queryPageSize) || undefined;
  } catch (e) {
    queryPageSize = undefined;
  }

  let pageSize = queryPageSize || 10;

  let queryOrdering = useQuery('ordering');

  let {ordering} = useSelector(getListing)(listingId);
  if (queryOrdering) {
    if (ordering?.length !== 1 || ordering[0] !== queryOrdering) {
      ordering = [queryOrdering];
    }
  } else if (!ordering) {
    ordering = ['musik_titel'];
  }

  let queryTodoTaskId = useQuery('todoTaskId');
  useEntityObserver({type: 'todo_task', id: queryTodoTaskId});
  const todoTask = useSelector(getTodoTaskGetter)(queryTodoTaskId);

  let queryTodoTasks = useQuery('todoTasks') === 'true';
  let todoTasks = queryTodoTasks || queryTodoTaskId;

  let queryTodoType = todoTask?.todo_type; //useQuery('todoType');
  let todoType = queryTodoType;

  const changeQueryString = (
    {
      pageSize,
      ordering,
      todoTasks,
      todoTaskId,
    },
  ) => {
    let url = '?';

    if (pageSize === undefined) {
      pageSize = queryPageSize;
    }
    if (pageSize !== undefined && pageSize !== null) {
      url += `pageSize=${pageSize}&`;
    }

    if (ordering === undefined) {
      ordering = queryOrdering;
    }
    if (ordering !== undefined && ordering !== null) {
      url += `ordering=${ordering}&`;
    }

    if (todoTasks === undefined) {
      todoTasks = queryTodoTasks;
    }
    if (todoTasks !== undefined && todoTasks !== null) {
      url += `todoTasks=${todoTasks}&`;
    }

    if (todoTaskId === undefined) {
      todoTaskId = queryTodoTaskId;
    }
    if (todoTaskId !== undefined && todoTaskId !== null) {
      url += `todoTaskId=${todoTaskId}&`;
    }

    if (url.endsWith('&')) {
      url = url.slice(0, -1);
    }

    navigate(url);
  };

  useEffect(() => {
    if (organizationId && listingId && (!queryTodoTaskId || queryTodoTaskId && todoType)) {
      dispatch(updateListing({
        id: listingId,
        meta: {
          ...listingMeta,
          ...meta,
          todo_tasks: todoTasks,
          todo_type: todoType,
        },
        currentPage: 1,
        ordering,
        pageSize,
        searchQuery: '',
        endpoint: `/api/sendemeldung/organizations/${organizationId}/org_music_works/`,
        entityType: 'org_music_work',
      }));
    } else {
      // TODO: Clear listing (and/or do that in unmount effect).
    }
  }, [organizationId, listingId, pageSize, ordering, todoTasks, todoType, queryTodoTaskId]);

  if (totalCount === undefined) {
    totalCount = count;
  }

  const updateOrdering = (ordering) => changeQueryString({ordering});

  const visibleIds = useSelector(getVisibleIdsForListing)(listingId);

  const [editWork, setEditWork] = useState(null);

  return (
    <>
      {totalCount || todoTasks ? (
        <Grid container mb={2}>
          <Grid item xs={6}>
            <Chip
              label="alle anzeigen"
              color={todoTasks ? undefined : "primary"}
              onClick={todoTasks ? (
                () => changeQueryString({todoTasks: null, todoTaskId: null})
              ) : undefined}
              clickable
            />
            &nbsp;
            <Chip
              label="nur Hinweise"
              color={todoTasks && !queryTodoTaskId ? "primary" : undefined}
              onClick={todoTasks && !queryTodoTaskId ? undefined : (
                () => changeQueryString({todoTasks: true, todoTaskId: null})
              )}
              clickable
            />
            {todoTasks && queryTodoTaskId ? (
              <>
                &nbsp;
                <Chip
                  label="nur ausgewählter Hinweis"
                  color={"primary"}
                  clickable
                />
              </>
            ) : null}
            <br/><br/>
          </Grid>
          <Grid item xs={6}>
            <Pagination
              force={forcePagination}
              listingId={listingId}
              setPageSize={(pageSize) => {
                changeQueryString({pageSize});
              }}
            />
          </Grid>
        </Grid>
      ) : null}

      {queryTodoTaskId ? (
        <Box mb={2}>
          <TodoTask
            id={queryTodoTaskId}
            gemagvlxmlLieferungId={gemagvlxmlLieferungId}
            hideMusicWorksButton
          />
        </Box>
      ) : null}

      {visibleIds.length === 0 ? (
        totalCount === 0 ? (
          noEntriesContent || children
        ) : count === undefined ? (
          loadingContent || children
        ) : (
          children
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <OrderableTableHeaderCell
                  ordering={ordering}
                  updateOrdering={updateOrdering}
                  columnName={'musik_titel'}
                >
                  Titel
                </OrderableTableHeaderCell>
                <TableCell>Urheber</TableCell>
                <TableCell>Interpret</TableCell>
                <TableCell align="center">Produktion-IDs</TableCell>
                <TableCell align="center">Tonaufnahme</TableCell>
                <OrderableTableHeaderCell
                  ordering={ordering}
                  updateOrdering={updateOrdering}
                  columnName={'created_at'}
                >
                  Erstellt
                </OrderableTableHeaderCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleIds?.map((id, i) => (
                <MusicWorkRow
                  key={id || i}
                  id={id}
                  onEdit={setEditWork}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editWork ? (
        <MusicWorkForm
          data={editWork}
          onClose={() => setEditWork(null)}
        />
      ) : null}
    </>
  );
}
