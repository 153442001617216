import {Chip, IconButton, TableCell, TableRow} from "@material-ui/core";
import {Add, Edit} from "@material-ui/icons";
import React from "react";
import {getStationGetter} from "src/features/entity";
import {useSelector} from "react-redux";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {Alert} from "@material-ui/lab";

export default function ProgrammeRow({stationId, editStation}) {
  const {
    name,
    gemagvl_sender_prg_id,
    gemagvl4_sender,
    gemagvl4_prognr,
    isDeleted,
  } = useSelector(getStationGetter)(stationId);

  useEntityObserver({type: 'station', id: stationId});

  if (isDeleted) {
    return (
      <TableRow>
        <TableCell colSpan={3}>
          <Alert variant="filled" color="error" severity="success">
            Dieses Programm wurde gelöscht.
          </Alert>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {name ? (
          name
        ) : (
          <Chip
            icon={<Add/>}
            label="Programmname ergänzen"
            variant="outlined"
            clickable
            color="primary"
            onClick={() => editStation(stationId)}
          />
        )}
      </TableCell>
      <TableCell>
        {!gemagvl_sender_prg_id && !gemagvl4_sender ? (
          <>
            <Chip
              icon={<Add/>}
              label="Programm-Kennzeichen ergänzen"
              variant="outlined"
              clickable
              color="primary"
              onClick={() => editStation(stationId)}
            />
          </>
        ) : (
          <>
            {gemagvl_sender_prg_id ? (
              <p style={{marginTop: 0}}>
                {gemagvl_sender_prg_id}
                {' '}
                <Chip label="GEMAGVL-XML" size="small"/>
              </p>
            ) : (
              <Chip
                icon={<Add/>}
                label="Angaben für GEMAGVL-XML ergänzen"
                variant="outlined"
                clickable
                color="primary"
                onClick={() => editStation(stationId)}
              />
            )}

            {gemagvl4_sender ? (
              <p style={{marginBottom: 0}}>
                {gemagvl4_sender}
                {gemagvl4_prognr !== '' ? ` / ${gemagvl4_prognr}` : null}
                {' '}
                <Chip label="GEMAGVL4" size="small"/>
              </p>
            ) : (
              <Chip
                icon={<Add/>}
                label="Angaben für GEMAGVL4 ergänzen"
                variant="outlined"
                clickable
                color="primary"
                onClick={() => editStation(stationId)}
              />
            )}
          </>
        )}
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="edit"
          onClick={() => editStation(stationId)}
        >
          <Edit/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
