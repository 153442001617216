import React from "react";
import {useSelector} from "react-redux";
import {getMusikPersonGetter} from "src/features/entity";
import {Chip, IconButton} from "@material-ui/core";
import {Edit} from "@material-ui/icons";
import {MUSIK_PERSON_ROLLE_KNZ} from "src/features/dashboard/dashboard-validation";

export const URHEBER_LIST = ['K', 'B', 'SB', 'T', 'ST', 'TS'];
export const INTERPRET_LIST = ['INT', 'DIRIG', 'ENS', 'GRUPPE', 'ORCH', 'SON'];
export const VERLEGER_LIST = ['V', 'OV', 'SV'];

function MusikPersonRolle({knz, hideInterpretRole, hideVerlagRole}) {
  if (hideInterpretRole && knz === 'INT') {
    return null;
  }
  if (hideVerlagRole && knz === 'V') {
    return null;
  }

  let description = MUSIK_PERSON_ROLLE_KNZ[knz];
  if (description === undefined) {
    return null;
  }

  return (
    <Chip label={description} size="small"/>
  );
}

export default function MusikPerson({id, showRole, onEdit, hideEdit, ...props}) {
  const getMusikPerson = useSelector(getMusikPersonGetter);

  const {organization, musik_person_rolle_knz, name, vorname} = getMusikPerson(id);

  const roleBadge = showRole ? (
    <MusikPersonRolle knz={musik_person_rolle_knz} {...props}/>
  ) : null;

  return (
    <>
      {vorname} {name}
      {roleBadge ? (
        <>
          {' '}
          {roleBadge}
        </>
      ) : null}
      {(onEdit && !hideEdit) ? (
        <>
          {' '}
          <IconButton
            aria-label="edit"
            onClick={onEdit ? (() => onEdit({id, organization, musik_person_rolle_knz, name, vorname})) : null}
            size="small"
          >
            <Edit/>
          </IconButton>
        </>
      ) : null}
    </>
  );
}
