import {Box, Chip, Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getListing, getVisibleIdsForListing, updateListing} from "src/features/ui/listing";
import React, {useEffect, useState} from "react";
import {Pagination} from "src/components/paginator";
import {getGEMAGVLXMLLieferungGetter, getTodoTaskGetter} from "src/features/entity";
import PlaylistItem from "src/components/entities/gemagvlxml/components/PlaylistItem";
import {useQuery} from "src/packages/route-utils";
import {navigate} from "@reach/router";
import {getSelectedOrganizationId} from "src/features/dashboard";
import GEMAGVLXMLAusstrahlungFormDialog from "src/components/entities/gemagvlxml/GEMAGVLXMLAusstrahlungFormDialog";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {TodoTask} from "src/components/entities/todotask/TodoTask";

export default function Playlist(
  {
    gemagvlxml_lieferung,
    orgMusicWorkId,
    listingId,
    meta,
    children,
    noEntriesContent,
    loadingContent,
    totalCount,
    showReports,
  }
) {
  const dispatch = useDispatch();

  let {organization: organizationId, stations} = useSelector(getGEMAGVLXMLLieferungGetter)(gemagvlxml_lieferung);
  const selectedOrganizationid = useSelector(getSelectedOrganizationId);

  if (gemagvlxml_lieferung === undefined) {
    organizationId = selectedOrganizationid;
  }

  listingId = `${organizationId}/${listingId}`;
  const {count, meta: listingMeta} = useSelector(getListing)(listingId);

  const showStations = stations?.length !== 1;

  let queryPageSize = useQuery('pageSize');
  try {
    queryPageSize = parseInt(queryPageSize) || undefined;
  } catch (e) {
    queryPageSize = undefined;
  }

  let pageSize = queryPageSize || 10;

  let queryOrdering = useQuery('ordering');

  let {ordering} = useSelector(getListing)(listingId);
  if (queryOrdering) {
    if (ordering?.length !== 1 || ordering[0] !== queryOrdering) {
      ordering = [queryOrdering];
    }
  } else if (!ordering) {
    ordering = ['lineno'];
  }

  let queryTodoTaskId = useQuery('todoTaskId');
  useEntityObserver({type: 'todo_task', id: queryTodoTaskId});
  const todoTask = useSelector(getTodoTaskGetter)(queryTodoTaskId);

  let queryTodoTasks = useQuery('todoTasks') === 'true';
  let todoTasks = queryTodoTasks || queryTodoTaskId;

  let queryTodoType = todoTask?.todo_type; //useQuery('todoType');
  let todoType = queryTodoType;

  const changeQueryString = (
    {
      pageSize,
      ordering,
      todoTasks,
      todoTaskId,
    },
  ) => {
    let url = '?';

    if (pageSize === undefined) {
      pageSize = queryPageSize;
    }
    if (pageSize !== undefined && pageSize !== null) {
      url += `pageSize=${pageSize}&`;
    }

    if (ordering === undefined) {
      ordering = queryOrdering;
    }
    if (ordering !== undefined && ordering !== null) {
      url += `ordering=${ordering}&`;
    }

    if (todoTasks === undefined) {
      todoTasks = queryTodoTasks;
    }
    if (todoTasks !== undefined && todoTasks !== null) {
      url += `todoTasks=${todoTasks}&`;
    }

    if (todoTaskId === undefined) {
      todoTaskId = queryTodoTaskId;
    }
    if (todoTaskId !== undefined && todoTaskId !== null) {
      url += `todoTaskId=${todoTaskId}&`;
    }

    if (url.endsWith('&')) {
      url = url.slice(0, -1);
    }

    navigate(url);
  };

  useEffect(() => {
    if (organizationId && listingId && (gemagvlxml_lieferung || orgMusicWorkId) && (!queryTodoTaskId || queryTodoTaskId && todoType)) {
      dispatch(updateListing({
        id: listingId,
        meta: {
          ...listingMeta,
          ...meta,
          music_work: orgMusicWorkId,
          todo_tasks: todoTasks,
          todo_type: todoType,
        },
        currentPage: 1,
        ordering,
        pageSize,
        searchQuery: '',
        endpoint: (gemagvlxml_lieferung ? (
          `/api/sendemeldung/organizations/${organizationId}/gemagvlxml_lieferungen/${gemagvlxml_lieferung}/ausstrahlungen/`
        ) : (
          `/api/sendemeldung/organizations/${organizationId}/gemagvlxml_ausstrahlungen2/`
        )),
        entityType: 'gemagvlxml_ausstrahlung',
      }));
    } else {
      // TODO: Clear listing (and/or do that in unmount effect).
    }
  }, [organizationId, listingId, pageSize, ordering, todoTasks, todoType, queryTodoTaskId]);

  if (totalCount === undefined) {
    totalCount = count;
  }

  const visibleIds = useSelector(getVisibleIdsForListing)(listingId);

  const [editAusstrahlung, setEditAusstrahlung] = useState(null);

  let previousId = null;

  return (
    <>
      {totalCount || todoTasks ? (
        <Grid container mb={2}>
          <Grid item xs={6}>
            <Chip
              label="alle anzeigen"
              color={todoTasks ? undefined : "primary"}
              onClick={todoTasks ? (
                () => changeQueryString({todoTasks: null, todoTaskId: null})
              ) : undefined}
              clickable
            />
            &nbsp;
            <Chip
              label="nur Hinweise"
              color={todoTasks && !queryTodoTaskId ? "primary" : undefined}
              onClick={todoTasks && !queryTodoTaskId ? undefined : (
                () => changeQueryString({todoTasks: true, todoTaskId: null})
              )}
              clickable
            />
            {todoTasks && queryTodoTaskId ? (
              <>
                &nbsp;
                <Chip
                  label="nur ausgewählter Hinweis"
                  color={"primary"}
                  clickable
                />
              </>
            ) : null}
            <br/><br/>
          </Grid>
          <Grid item xs={6}>
            <Pagination
              force
              listingId={listingId}
              setPageSize={(pageSize) => {
                changeQueryString({pageSize});
              }}
            />
          </Grid>
        </Grid>
      ) : null}

      {queryTodoTaskId ? (
        <Box mb={2}>
          <TodoTask
            id={queryTodoTaskId}
            gemagvlxmlLieferungId={gemagvlxml_lieferung}
            hidePlaylistButton
          />
        </Box>
      ) : null}

      {visibleIds.length === 0 ? (
        totalCount === 0 ? (
          noEntriesContent || children
        ) : count === undefined ? (
          loadingContent || children
        ) : (
          children
        )
      ) : (
        <>
          {visibleIds?.map((id, i) => {
            const result = (
              <PlaylistItem
                key={id || i}
                id={id}
                previousId={previousId}
                onEdit={setEditAusstrahlung}
                showStation={showStations}
                showReport={showReports}
              />
            );
            previousId = id;
            return result;
          })}
        </>
      )}

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editAusstrahlung ? (
        <GEMAGVLXMLAusstrahlungFormDialog
          data={editAusstrahlung}
          onClose={() => setEditAusstrahlung(null)}
        />
      ) : null}
    </>
  );
}
