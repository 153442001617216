import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";

export default function BillingPage() {
  return (
    <DashboardLayout
      titlePrefix="Abrechnung"
      selectedPage="billing"
      drawerContentProps={{expandSettings: true}}
    >
      <Box my={2}>
        <Typography variant="h4">
          Abrechnung
        </Typography>
      </Box>
      <Alert variant="outlined" severity="info">
        Die Nutzung von Sendemeldung.de während der Entwicklungsphase bis Ende 2021 ist durch Ihren Finanzierungsanteil
        abgegolten. Für den Produktivbetrieb ab 2022 werden Sie hier rechtzeitig Informationen zur Abrechnung finden.
      </Alert>
    </DashboardLayout>
  );
}
