import {useSelector} from "react-redux";
import {getErschieneneTonaufnahmeGetter} from "src/features/entity";
import {Add, Album, Edit} from "@material-ui/icons";
import {Chip, IconButton, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {ErschieneneTonaufnahmeForm} from "src/components/entities/musicwork/ErschieneneTonaufnahmeForm";
import {Skeleton} from "@material-ui/lab";

export default function ErschieneneTonaufnahme({id, full, onEdit}) {
  const getErschieneneTonaufnahme = useSelector(getErschieneneTonaufnahmeGetter);
  const erschienene_tonaufnahme = getErschieneneTonaufnahme(id);
  const {
    id: tonaufnahmeId,
    label,
    label_code,
    deklaration_tonaufnahme_knz,
    album_titel,
    voe_datum,
    sonstige_info
  } = erschienene_tonaufnahme;

  const [editErschieneneTonaufnahme, setEditErschieneneTonaufnahme] = useState(null);

  if (!tonaufnahmeId) {
    return (
      <Skeleton variant="text"/>
    );
  }

  const formattedLabelCode = (label_code ? (
    <Typography variant="inherit">
      <Chip label={(
        label_code < 100000 ? (
          ('0000' + label_code).slice(-5)
        ) : (
          ('0000000' + label_code).slice(-8)
        )
      )}
      />
    </Typography>
  ) : null);

  const addLabelButton = (
    <Chip
      icon={<Add/>}
      variant="outlined"
      color="primary"
      clickable
      label="Label ergänzen"
      onClick={() => setEditErschieneneTonaufnahme({id, ...erschienene_tonaufnahme})}
    />
  );

  const addLabelCodeButton = (
    <Chip
      icon={<Add/>}
      variant="outlined"
      color="primary"
      clickable
      label="LC ergänzen"
      onClick={() => setEditErschieneneTonaufnahme({id, ...erschienene_tonaufnahme})}
    />
  );

  const addVoeDatumButton = (
    <Chip
      icon={<Add/>}
      variant="outlined"
      color="primary"
      clickable
      label="Veröffentlichungsdatum ergänzen"
      onClick={() => setEditErschieneneTonaufnahme({id, ...erschienene_tonaufnahme})}
    />
  );

  const addAlbumTitelVoeDatumSonstigeInfo = (
    <Chip
      icon={<Add/>}
      variant="outlined"
      color="primary"
      clickable
      label="Angaben ergänzen"
      onClick={() => setEditErschieneneTonaufnahme({id, ...erschienene_tonaufnahme})}
    />
  );

  return (
    <>
      {deklaration_tonaufnahme_knz === 'LABLC' ? (
        <div>
          <Album color="action" style={{verticalAlign: 'middle'}}/>
          {' '}
          {formattedLabelCode}
          {' '}
          {label}
          {' '}
          {!label_code ? addLabelCodeButton : null}
        </div>
      ) : deklaration_tonaufnahme_knz === 'LABEL' ? (
        <div>
          <Album color="action" style={{verticalAlign: 'middle'}}/>
          {' '}
          {label ? label : addLabelButton}
        </div>
      ) : deklaration_tonaufnahme_knz === 'PROMO' ? (
        <div>
          <Album color="action" style={{verticalAlign: 'middle'}}/>
          {' '}
          <Chip label="Promo-CD"/>
        </div>
      ) : deklaration_tonaufnahme_knz === 'SELFRELEASED' ? (
        <div>
          <Album color="action" style={{verticalAlign: 'middle'}}/>
          {' '}
          <Chip label="self-released"/>
        </div>
      ) : deklaration_tonaufnahme_knz === 'OHNE' ? (
        <div>
          <Album color="action" style={{verticalAlign: 'middle'}}/>
          {' '}
          <Chip label="ohne Deklaration"/>
        </div>
      ) : deklaration_tonaufnahme_knz ? (
        deklaration_tonaufnahme_knz
      ) : null}

      {full ? (
        <>
          {(album_titel || voe_datum || sonstige_info) ? (
            <div>&nbsp;</div>
          ) : null}

          {album_titel ? (
            <div>
              {album_titel}
              {' '}
              <Chip label="Album-Titel" size="small"/>
            </div>
          ) : null}

          {voe_datum ? (
            <div>
              {voe_datum}
              {' '}
              <Chip label="Datum der Veröffentlichung" size="small"/>
            </div>
          ) : null}

          {sonstige_info ? (
            <div>
              {sonstige_info}
              {' '}
              <Chip label="sonstige Information" size="small"/>
            </div>
          ) : null}
        </>
      ) : null}

      {(deklaration_tonaufnahme_knz === 'SELFRELEASED' && !voe_datum) ? (
        addVoeDatumButton
      ) : null}

      {(deklaration_tonaufnahme_knz === 'OHNE' && !voe_datum && !album_titel && !sonstige_info) ? (
        addAlbumTitelVoeDatumSonstigeInfo
      ) : null}

      {onEdit ? (
        <>
          {' '}
          <IconButton
            aria-label="edit"
            onClick={onEdit ? (() => onEdit({id, ...erschienene_tonaufnahme})) : null}
            size="small"
          >
            <Edit/>
          </IconButton>
        </>
      ) : null}

      {editErschieneneTonaufnahme ? (
        <div align="left">
          <ErschieneneTonaufnahmeForm
            data={editErschieneneTonaufnahme}
            onClose={() => setEditErschieneneTonaufnahme(null)}
          />
        </div>
      ) : null}
    </>
  );
}
