import React, {useEffect} from "react";
import {Router} from "@reach/router";
import {useSelector} from "react-redux";
import {navigate} from "gatsby";
import ReportsPage from "src/components/dashboard_pages/reports";
import ReportPage from "src/components/dashboard_pages/report";
import UploadedFileReportsPage from "src/components/dashboard_pages/uploaded_file_reports";
import {isLoggedIn} from "src/features/session";
import DatabasePage from "src/components/dashboard_pages/archive";
import BillingPage from "src/components/dashboard_pages/billing";
import ContactPage from "src/components/dashboard_pages/contact";
import DocumentsPage from "src/components/dashboard_pages/documents";
import NotificationsPage from "src/components/dashboard_pages/notifications";
import OrganizationPage from "src/components/dashboard_pages/organization";
import PeoplePage from "src/components/dashboard_pages/people";
import ProgrammesPage from "src/components/dashboard_pages/programmes";
import SecurityPage from "src/components/dashboard_pages/security";
import DashboardPage from "src/components/dashboard_pages";
import MusicWorkPage from "src/components/dashboard_pages/music_work";
import NotFoundPage from "src/components/dashboard_pages/404";

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de";
import TimelinePage from "src/components/dashboard_pages/timeline";
import ChangelogPage from "src/components/dashboard_pages/changelog";
import ImportPage from "src/components/dashboard_pages/import";


const PrivateRoute = ({component: Component, location, ...rest}) => {
  const loggedIn = useSelector(isLoggedIn);

  const neitherLoggedInNorLoginPage = (!loggedIn && location.pathname !== `/dashboard/login`);

  useEffect(() => {
    if (neitherLoggedInNorLoginPage) {
      navigate("/");
    }
  }, [neitherLoggedInNorLoginPage]);

  if (neitherLoggedInNorLoginPage) {
    return null;
  }

  return (
    <Component {...rest} />
  );
}

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <Router basepath="/dashboard">
        <PrivateRoute path="/archive" component={DatabasePage}/>
        <PrivateRoute path="/archive/:id" component={MusicWorkPage}/>
        <PrivateRoute path="/archive/:id/*" component={MusicWorkPage}/>
        <PrivateRoute path="/billing" component={BillingPage}/>
        <PrivateRoute path="/changelog" component={ChangelogPage}/>
        <PrivateRoute path="/contact" component={ContactPage}/>
        <PrivateRoute path="/documents" component={DocumentsPage}/>
        <PrivateRoute path="/import" component={ImportPage}/>
        <PrivateRoute path="/notifications" component={NotificationsPage}/>
        <PrivateRoute path="/organization" component={OrganizationPage}/>
        <PrivateRoute path="/people" component={PeoplePage}/>
        <PrivateRoute path="/programmes" component={ProgrammesPage}/>
        <PrivateRoute path="/reports" component={ReportsPage}/>
        <PrivateRoute path="/reports/:id/*" component={ReportPage}/>
        <PrivateRoute path="/security" component={SecurityPage}/>
        <PrivateRoute path="/timeline" component={TimelinePage}/>
        <PrivateRoute path="/uploads/:id/reports" component={UploadedFileReportsPage}/>
        <PrivateRoute path="/" component={DashboardPage}/>
        <PrivateRoute default component={NotFoundPage}/>
      </Router>
    </MuiPickersUtilsProvider>
  )
};

export default App;
