import React, {useEffect, useMemo} from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Grid,} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {getPendingFileInternalIds, getSelectedOrganizationId} from "src/features/dashboard";
import _ from 'lodash';
import Dropzone from "src/components/dashboard/dropzone-redux";
import UploadedFileRow from "src/components/entities/upload/UploadedFileRow";
import UploadingFileRow from "src/components/entities/upload/UploadingFileRow";
import {
  getInvisibleCreatedIdsByOrganizationForListing,
  getListing,
  getVisibleIdsForListing,
  updateListing
} from "src/features/ui/listing";
import {Pagination} from "src/components/paginator";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {AlertTitle} from "@material-ui/lab";

export default function ImportPage() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = useMemo(() => _.memoize((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }), [setExpanded]);

  const dispatch = useDispatch();

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);
  const listingId = `${selectedOrganizationId}/uploaded_files`;

  const listing = useSelector(getListing)(listingId);
  const {count} = listing;
  let totalCount = count;

  useEffect(() => {
    if (selectedOrganizationId && listingId) {
      dispatch(updateListing({
        id: listingId,
        currentPage: 1,
        pageSize: 10,
        ordering: [],
        searchQuery: '',
        endpoint: `/api/sendemeldung/organizations/${selectedOrganizationId}/uploaded_files/`,
        entityType: 'uploaded_file',
      }));
    } else {
      // TODO: Clear listing (and/or do that in unmount effect).
    }
  }, [selectedOrganizationId, listingId]);

  const visibleIds = useSelector(getVisibleIdsForListing)(listingId);
  let createdIds = useSelector(getInvisibleCreatedIdsByOrganizationForListing)(listingId)(selectedOrganizationId);
  createdIds = [...createdIds];
  createdIds.reverse();

  const renderIds = [...createdIds, ...visibleIds];

  const uploadingFileIds = useSelector(getPendingFileInternalIds);

  return (
    <DashboardLayout
      titlePrefix="GEMAGVL4-Import"
      selectedPage="import"
    >
      <Box my={2}>
        <Typography variant="h4">
          Hochgeladene Dateien
        </Typography>
      </Box>

      <Dropzone/>

      <br/>

      <Grid container>
        <Grid item xs={12}>
          {totalCount ? (
            <Pagination listingId={listingId}/>
          ) : null}

          {uploadingFileIds?.map((internalId, i) => (
            <UploadingFileRow
              key={internalId || i}
              internalId={internalId}
            />
          ))}

          {renderIds.length === 0 ? (
            totalCount === 0 ? (
              <Alert variant="filled" severity="info">
                Es wurden noch keine Vorgänge erstellt.
              </Alert>
            ) : count === undefined ? (
              <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                Vorgänge werden geladen...
              </Alert>
            ) : (
              <Alert variant="filled" severity="info">
                Diese Ansicht enthält keine Einträge.
              </Alert>
            )
          ) : (
            renderIds?.map((id, i) => (
              <UploadedFileRow
                key={id || i}
                id={id}
                expanded={expanded === id}
                onChange={handleChange(id)}
                onlyRoot
              />
            ))
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
