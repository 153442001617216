import {useSelector} from "react-redux";
import {getErschieneneTonaufnahmeGetter, getMusikProduktionIdGetter, getOrgMusicWorkGetter} from "src/features/entity";
import {Chip} from "@material-ui/core";
import React, {useState} from "react";
import {Add} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import MusikProduktionId from "src/components/entities/musicwork/components/MusikProduktionId";
import {MusicProductionIDForm} from "src/components/entities/musicwork/MusicProductionIDForm";

export default function MusikProduktionIds({id, ...props}) {
  const {
    id: musikId,
    organization,
    musik_produktion_ids,
    erschienene_tonaufnahme,
  } = useSelector(getOrgMusicWorkGetter)(id);
  const getMusikProduktionId = useSelector(getMusikProduktionIdGetter);

  const {deklaration_tonaufnahme_knz} = useSelector(getErschieneneTonaufnahmeGetter)(erschienene_tonaufnahme);

  const [editProduktionId, setEditProduktionId] = useState(null);

  if (!musikId) {
    return (
      <Skeleton variant="text"/>
    );
  }

  let isMissingIsrcOrEanOrKatnr = (deklaration_tonaufnahme_knz === 'LABLC' || deklaration_tonaufnahme_knz === 'LABEL');
  if (isMissingIsrcOrEanOrKatnr) {
    musik_produktion_ids.map(id => getMusikProduktionId(id)).forEach(({id_typ_knz}) => {
      if (id_typ_knz === 'ISRC' || id_typ_knz === 'EAN_UPC' || id_typ_knz === 'KATALOG_NR') {
        isMissingIsrcOrEanOrKatnr = false;
      }
    });
  }

  const addProduktionIdButton = (
    <Chip
      icon={<Add/>}
      variant="outlined"
      color="primary"
      clickable
      label="Produktion-ID ergänzen"
      onClick={() => setEditProduktionId({organization, org_music_work: id})}
    />
  );

  return (
    <>
      {musik_produktion_ids?.map((id, i) => (
        <p key={id || i}>
          <MusikProduktionId
            id={id}
            showType
            onEdit={setEditProduktionId}
            {...props}
          />
        </p>
      ))}

      {isMissingIsrcOrEanOrKatnr ? (
        addProduktionIdButton
      ) : null}

      {editProduktionId ? (
        <div align="left">
          <MusicProductionIDForm
            data={editProduktionId}
            onClose={() => setEditProduktionId(null)}
          />
        </div>
      ) : null}
    </>
  );
}
