import {IconButton} from "@material-ui/core";
import React from "react";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";

export default function HrefIconButton(props) {
  return (
    <HrefComponent
      component={IconButton}
      {...props}
    />
  );
}
