import {useSelector} from "react-redux";
import {getOrgMusicWorkGetter} from "src/features/entity";
import {Chip} from "@material-ui/core";
import React, {useState} from "react";
import {Add} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import {MusicNutzungForm} from "src/components/entities/musicwork/MusicNutzungForm";
import {MUSIK_HERKUNFT_KNZ} from "src/features/dashboard/dashboard-validation";

export default function MusikHerkunft({id, showOnlyButton}) {
  const {
    id: musikId,
    organization,
    nutzung_art_knz,
    aufnahme_datum,
    prod_titel,
    prod_ort,
    musik_herkunft_knz,
  } = useSelector(getOrgMusicWorkGetter)(id);

  const [editMusicNutzung, setEditMusicNutzung] = useState(null);

  let renderedHerkunft = MUSIK_HERKUNFT_KNZ[musik_herkunft_knz];

  if (showOnlyButton && (!musikId || renderedHerkunft)) {
    return null;
  }

  if (!musikId) {
    return (
      <Skeleton variant="text"/>
    );
  }

  const addHerkunftButton = (
    <Chip
      icon={<Add/>}
      variant="outlined"
      color="primary"
      clickable
      label="Musikherkunft ergänzen"
      onClick={() => setEditMusicNutzung({
        id,
        organization,
        nutzung_art_knz,
        aufnahme_datum,
        prod_titel,
        prod_ort,
        musik_herkunft_knz
      })}
    />
  );

  if (!renderedHerkunft) {
    renderedHerkunft = addHerkunftButton;
  }

  return (
    <>
      {renderedHerkunft}
      {editMusicNutzung ? (
        <div align="left">
          <MusicNutzungForm
            data={editMusicNutzung}
            onClose={() => setEditMusicNutzung(null)}
          />
        </div>
      ) : null}
    </>
  );
}
