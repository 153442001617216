import {useSelector} from "react-redux";
import {getStationGetter} from "src/features/entity";
import {Chip} from "@material-ui/core";
import {Edit, Warning} from "@material-ui/icons";
import React, {useState} from "react";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {Skeleton} from "@material-ui/lab";
import {ProgrammeForm} from "src/components/entities/programme/ProgrammeForm";

export default function ProgrammeChip({id}) {
  const getStation = useSelector(getStationGetter);
  const {id: stationId, name, gemagvl_sender_prg_id, gemagvl4_sender} = getStation(id);

  const [editStation, setEditStation] = useState(null);

  useEntityObserver({type: 'station', id});

  if (!stationId) {
    return (
      <Skeleton>
        <Chip label="test"/>
      </Skeleton>
    );
  }

  const doEditStation = (event) => {
    event.stopPropagation();
    setEditStation(getStation(stationId));
  };

  const noop = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      {gemagvl_sender_prg_id ? (
        <Chip
          clickable={false}
          variant="outlined"
          onClick={noop}
          // icon={<Radio color="action"/>}
          deleteIcon={<Edit/>}
          onDelete={doEditStation}
          label={name || gemagvl_sender_prg_id}
        />
      ) : gemagvl4_sender ? (
        <Chip
          clickable
          // icon={<Radio color="action"/>}
          variant="outlined"
          onClick={doEditStation}
          label={(
            <>
              {name || gemagvl4_sender}
              {' '}
              <Chip
                clickable
                icon={<Warning/>}
                label="XML-Programm-ID fehlt"
                color="primary"
                size="small"
              />
            </>
          )}
        />
      ) : (
        <Chip
          clickable
          // icon={<Radio color="action"/>}
          variant="outlined"
          onClick={doEditStation}
          label={(
            <>
              <Chip
                clickable
                icon={<Warning/>}
                label="unbekanntes Programm"
                color="primary"
                size="small"
              />
            </>
          )}
        />
      )}
      {editStation ? (
        <ProgrammeForm
          data={editStation}
          onClose={() => setEditStation(null)}
        />
      ) : null}
    </>
  );
}
