import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {MUSIC_WORK_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {ORG_MUSIC_WORK} from "src/api/api-schemas";

export function MusicNutzungForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(ORG_MUSIC_WORK);

  const saveWork = async (validatedData) => {
    const {organization, id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/organizations/${organization}/org_music_works/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'edit-music-nutzung'}
      title={data?.id ? "Musiknutzung bearbeiten" : "Musiknutzung anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveWork}
      schema={MUSIC_WORK_SCHEMA}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <YupField name="nutzung_art_knz"/>
          </Grid>
          <Grid item xs={6}>
            <YupField name="aufnahme_datum"/>
          </Grid>
          <Grid item xs={6}>
            <YupField name="prod_titel"/>
          </Grid>
          <Grid item xs={6}>
            <YupField name="prod_ort"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="musik_herkunft_knz"/>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
