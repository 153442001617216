import React, {useEffect, useState} from "react";
import {Box, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@material-ui/core";
import {DashboardLayout} from "src/components/layout";
import Typography from "@material-ui/core/Typography";
import {Add} from "@material-ui/icons";
import {getSelectedOrganization, getSelectedOrganizationId} from "src/features/dashboard";
import {useDispatch, useSelector} from "react-redux";
import Alert from "@material-ui/lab/Alert";
import {getContactGetter, getContactRoleGetter} from "src/features/entity";
import ContactRoleRow from "src/components/entities/person/ContactRoleRow";
import {
  getInvisibleCreatedIdsByOrganizationForListing,
  getListing,
  getVisibleIdsForListing,
  updateListing
} from "src/features/ui/listing";
import {Pagination} from "src/components/paginator";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PersonForm} from "src/components/entities/person/PersonForm";

export default function PersonTable() {
  const [editPerson, setEditPerson] = useState(null);

  const selectedOrganization = useSelector(getSelectedOrganization);

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);
  const listingId = `${selectedOrganizationId}/people`;

  const dispatch = useDispatch();
  const listing = useSelector(getListing)(listingId);
  const {count} = listing;
  let totalCount = count;

  useEffect(() => {
    if (selectedOrganizationId && listingId) {
      dispatch(updateListing({
        id: listingId,
        currentPage: 1,
        pageSize: 10,
        ordering: [],
        searchQuery: '',
        endpoint: `/api/sendemeldung/organizations/${selectedOrganizationId}/contact_roles/`,
        entityType: 'contact_role',
      }));
    } else {
      // TODO: Clear listing (and/or do that in unmount effect).
    }
  }, [selectedOrganizationId, listingId]);

  const visibleIds = useSelector(getVisibleIdsForListing)(listingId);
  const createdIds = useSelector(getInvisibleCreatedIdsByOrganizationForListing)(listingId)(selectedOrganizationId);

  const renderIds = [...visibleIds, ...createdIds];

  const getContactRole = useSelector(getContactRoleGetter);
  const getContact = useSelector(getContactGetter);

  const getContactAndRole = (contactRoleId) => {
    const {contact, ...role} = getContactRole(contactRoleId);
    return {
      contact: getContact(contact),
      ...role,
    };
  };

  return (
    <DashboardLayout
      titlePrefix="Personen"
      selectedPage="people"
      drawerContentProps={{expandSettings: true}}
    >
      <Box my={2}>
        <Typography variant="h4">
          Personen
        </Typography>
      </Box>

      {totalCount ? (
        <Pagination listingId={listingId}/>
      ) : null}

      {renderIds.length === 0 ? (
        totalCount === 0 ? (
          <Alert variant="filled" severity="info">
            Es wurden noch keine Personen eingerichtet.
          </Alert>
        ) : count === undefined ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Personen werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            Diese Ansicht enthält keine Einträge.
          </Alert>
        )
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="people table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>E-Mail</TableCell>
                <TableCell align="center">Login-Berechtigung</TableCell>
                <TableCell>Rollen</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderIds?.map((id, i) => (
                <ContactRoleRow
                  key={id || i}
                  contactRoleId={id}
                  editContactRole={(contactRoleId) => setEditPerson(getContactAndRole(contactRoleId))}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editPerson ? (
        <PersonForm
          data={editPerson}
          onClose={() => setEditPerson(null)}
        />
      ) : null}

      <Box mt={2} mx={1.5} align="right">
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => (
            // TODO: Outsource person bootstrap data to api/schemas.
            setEditPerson({
              organization: selectedOrganization.id,
              contact: {
                organizations: [selectedOrganization.id],
              },
            })
          )}>
          <Add/>
        </Fab>
      </Box>
    </DashboardLayout>
  );
}
