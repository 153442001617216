import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {ORGANIZATION_DETAILS_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {Box, Button, Collapse, DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {ORGANIZATION} from "src/api/api-schemas";
import {getOrganizationGetter} from "src/features/entity";
import {useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";

function LieferantIDFormDialogContent({data, ...props}) {
  const getOrganization = useSelector(getOrganizationGetter);
  const {suggested_gemagvlxml_lieferant_ids} = getOrganization(data?.id);

  const suggested_gemagvlxml_lieferant_id = (
    suggested_gemagvlxml_lieferant_ids?.length === 1 ? suggested_gemagvlxml_lieferant_ids[0] : null
  );

  const {setValue, watch} = useFormContext();

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YupField
            name="gemagvlxml_lieferant_id"
            suggestions={
              suggested_gemagvlxml_lieferant_ids ||
              (data?.gemagvlxml_lieferant_id ? [data?.gemagvlxml_lieferant_id] : [])
            }
          />
        </Grid>
        <Collapse in={!data?.gemagvlxml_lieferant_id}>
          <Box mt={2}>
            <Alert severity="info">
              <AlertTitle>Hinweis zur Lieferant-ID</AlertTitle>
              Ihre Lieferant-ID ist in der Regel identisch zu Ihrem Sender- bzw. Programmkennzeichen.
              Teilweise wurden von den Verwertungsgesellschaften aber auch separate Lieferant-IDs vergeben &ndash;
              beispielsweise dann, wenn Sie Meldungen für mehrere Programme übermitteln.
              {' '}
              {suggested_gemagvlxml_lieferant_id ? (
                <>
                  <p>
                    Auf Grundlage der uns vorliegenden Daten gehen wir davon aus, dass Ihre Lieferant-ID wie folgt
                    lautet:
                  </p>
                  <p>
                    <strong>{suggested_gemagvlxml_lieferant_id}</strong>
                  </p>
                  <Collapse in={watch('gemagvlxml_lieferant_id') !== suggested_gemagvlxml_lieferant_id}>
                    <p>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setValue('gemagvlxml_lieferant_id', suggested_gemagvlxml_lieferant_id)
                        }}
                      >
                        Lieferant-ID {suggested_gemagvlxml_lieferant_id} übernehmen
                      </Button>
                    </p>
                  </Collapse>
                </>
              ) : suggested_gemagvlxml_lieferant_ids?.length > 1 ? (
                <>
                  <p>
                    Auf Grundlage der uns vorliegenden Daten gehen wir davon aus, dass es sich bei einem der
                    folgenden Werte um Ihre Lieferant-ID handelt:
                  </p>
                  <ul>
                    {suggested_gemagvlxml_lieferant_ids.map((candidate, i) => (
                      <li key={candidate || i}>
                        {candidate}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  Wir haben von den Verwertungsgesellschaften eine entsprechende Liste erhalten und können Ihre
                  Lieferant-ID typischerweise automatisch vorschlagen, sobald Sie Sendemeldungen hochgeladen haben.
                  Sollte dies nicht der Fall sein und sollten Sie Ihre Lieferant-ID nicht kennen, kontaktieren Sie
                  uns bitte.
                </>
              )}
            </Alert>
          </Box>
        </Collapse>
      </Grid>
    </DialogContent>
  );
}

export default function LieferantIDFormDialog({data, ...props}) {
  const entityApi = useEntityApi(ORGANIZATION);

  const {id, gemagvlxml_lieferant_id} = data || {};

  const saveOrganization = async (validatedData) => {
    const {id} = validatedData;
    await entityApi.patch(
      `/api/sendemeldung/organizations/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      title={"Stammdaten für XML-Meldungen"}
      open={!!data}
      data={{id, gemagvlxml_lieferant_id}}
      submit={saveOrganization}
      schema={ORGANIZATION_DETAILS_SCHEMA}
      {...props}
    >
      <LieferantIDFormDialogContent
        data={data}
        {...props}
      />
    </SimpleYupFormDialog>
  );
}
