import React, {useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
  list: {
    marginTop: -theme.spacing(4),
  },
  fileInput: {
    display: 'none',
  }
}));

export default function Dropzone({uploadFiles}) {
  const classes = useStyles();

  const fileInputRef = useRef();

  return (
    <Paper
      variant="outlined"
      onDrop={event => {
        event.stopPropagation();
        event.preventDefault();
        uploadFiles(event.dataTransfer.files);
      }}
      onDragOver={event => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
      }}
    >
      <Typography component="div" variant="body1" className={classes.dropzone}>
        <>
          <p>bitte GEMAGVL4-Sendemeldung(en) hier hineinziehen</p>
          <p>oder</p>
        </>
        <Button
          variant="contained"
          color="primary"
          onClick={() => fileInputRef.current.click()}
        >
          Dateien vom Computer auswählen
        </Button>
        <input type='file' id='file' multiple ref={fileInputRef} className={classes.fileInput} onChange={event => {
          uploadFiles(event.target.files);
          fileInputRef.current.value = '';
        }}/>
      </Typography>
    </Paper>
  );
}
