import {useSelector} from "react-redux";
import {getContactGetter, getContactRoleGetter} from "src/features/entity";
import {Chip, IconButton, TableCell, TableRow} from "@material-ui/core";
import {AccountBalanceWallet, AccountBox, Add, Check, Edit, NotInterested, Person} from "@material-ui/icons";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  userProperties: {
    display: 'flex',
    marginLeft: -theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
    flexWrap: 'wrap',
  },
  namePrefix: {
    color: theme.palette.text.secondary,
  },
}));

export default function ContactRoleRow({contactRoleId, editContactRole}) {
  const classes = useStyles();

  const getContactRole = useSelector(getContactRoleGetter);
  const getContact = useSelector(getContactGetter);

  let {contact, ...role} = getContactRole(contactRoleId);
  contact = getContact(contact);

  if (contact?.isDeleted) {
    return (
      <TableRow>
        <TableCell colSpan={5}>
          <Alert variant="filled" color="error" severity="success">
            Dieses Person wurde gelöscht.
          </Alert>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow key={contact.id}>
      <TableCell component="th" scope="row">
        {contact.name ? (
          <>
            {contact.salutation || contact.title ? (
              <>
                <span className={classes.namePrefix}>
                  {/* TODO: Make reusable. */}
                  {contact.salutation === 'herr' ? (
                    "Herr "
                  ) : contact.salutation === 'frau' ? (
                    "Frau "
                  ) : null}
                  {contact.title}
                </span>
                <br/>
              </>
            ) : null}
            {contact.name}
          </>
        ) : (
          <Chip
            icon={<Add/>}
            label="Name ergänzen"
            variant="outlined"
            clickable
            color="primary"
            onClick={() => editContactRole(contactRoleId)}
          />
        )}
        {role?.is_request_user ? (
          <>
            <br/><br/>
            <Chip
              size="small"
              label="Sie sind als dieser Nutzer eingeloggt."
              color="secondary"
            />
          </>
        ) : null}
      </TableCell>
      <TableCell>{contact.email}</TableCell>
      <TableCell align="center">
        {role?.is_admin ? (
          <Check color="action"/>
        ) : (
          <NotInterested color="action"/>
        )}
      </TableCell>
      <TableCell>
        <div className={classes.userProperties}>
          {role?.is_admin ? (
            <Chip
              size="small"
              label="Administrator"
              color="secondary"
            />
          ) : null}
          {role?.is_gl ? (
            <Chip
              size="small"
              icon={<AccountBox/>}
              label="Geschäftsleitung"
              color="primary"
            />
          ) : null}
          {role?.is_technik ? (
            <Chip
              size="small"
              icon={<Person/>}
              label="Technik/Musikredaktion"
              color="primary"
            />
          ) : null}
          {role?.is_lira ? (
            <Chip
              size="small"
              icon={<AccountBalanceWallet/>}
              label="LIRA"
              color="primary"
            />
          ) : null}
        </div>
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="edit"
          onClick={() => editContactRole(contactRoleId)}
        >
          <Edit/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
