import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {STATION_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {Box, Card, CardContent, DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useEntityDeleter} from "src/features/entity/entity-hooks";
import {useSelector} from "react-redux";
import {getSelectedOrganization} from "src/features/dashboard";
import {STATION} from "src/api/api-schemas";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";

export function ProgrammeForm({data, onClose, ...props}) {
  const selectedOrganization = useSelector(getSelectedOrganization);

  const entityApi = useEntityApi(STATION);

  const saveStation = async (validatedData) => {
    const {organization, ...station} = validatedData;

    const stationUuid = station?.id;
    if (stationUuid) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${organization}/stations/${stationUuid}/`,
        validatedData,
      );
    } else {
      await entityApi.post(
        `/api/sendemeldung/organizations/${organization}/stations/`,
        validatedData,
        {createEntities: true, organization},
      );
    }

    // TODO: Generalize save mechanism.
  };

  const {deleteEntity, deletingUuids} = useEntityDeleter({
    entityType: 'stations',
    baseUrl: `/api/sendemeldung/organizations/${selectedOrganization?.id}/stations/`,
  });

  return (
    <SimpleYupFormDialog
      title={data?.id ? "Programm bearbeiten" : "Programm anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveStation}
      onDelete={
        // TODO: Implement suitable confirmation mechanism.
        // TODO: Track and display deletion status.
        data?.id ? (
          () => confirm("Programm wirklich löschen?") && deleteEntity(data?.id)
        ) : undefined
      }
      deleteCaption="Programm löschen"
      isDeleting={deletingUuids?.has(data?.id)}
      schema={STATION_SCHEMA}
      onClick={(event) => event.stopPropagation()}
      {...props}
    >
      <DialogContent style={{whiteSpace: 'normal'}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YupField name="name"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="gemagvl_sender_prg_id"/>
          </Grid>
          <Grid item xs={12}>
            <Card mt={2}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Einstellungen für GEMAGVL4-Import
                </Typography>
                <Typography gutterBottom>
                  Beim Import aus GEMAGVL4 werden diesem Programm alle Ausstrahlungen mit den folgenden Daten
                  zugeordnet:
                </Typography>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <YupField name="gemagvl4_sender"/>
                    </Grid>
                    <Grid item xs={6}>
                      <YupField name="gemagvl4_prognr"/>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <YupField name="report_jingles"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="max_overlapping_seconds"/>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>Hinweis zum Programm-Kennzeichen (SENDER_PRG_ID)</AlertTitle>
                Die XML-Programmkennzeichen sind laut Absprache mit GEMA / GVL identisch zum GEMAGVL4-Senderkennzeichen.
                Bitte ändern Sie dieses nur, wenn Sie von den Verwertungsgesellschaften dazu aufgefordert wurden.
              </Alert>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
