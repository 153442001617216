import {convertToBlob} from "src/packages/file-transfer";
import {v4 as uuidv4} from 'uuid';
import {uploadFile} from "./dashboard-slice";

export const createUploadFromFile = ({file, blob}, dispatch) => {
  // Convert provided file or blob into a blob that is safe for upload.
  const blobPromise = convertToBlob(file || blob);

  // Create document draft asynchronously.
  return new Promise((resolve, reject) => {
    // Determine file-related information.
    const metadata = file || blob;
    const {name, size} = metadata;

    // Yield blob and internalId.
    blobPromise.then(blob => resolve({internalId: uuidv4(), blob, name, size}));
  }).then(({internalId, name, size, blob}) => {
    // Start upload.
    dispatch(uploadFile({
      internalId,
      name,
      size,
      blob,
    }));

    return internalId;
  });
};

export const createUploadsFromFiles = ({files = [], blobs = [], ...other}, dispatch) => {
  return Promise.all([
    ...([...files].map(file => createUploadFromFile({file, ...other}, dispatch))),
    ...([...blobs].map(blob => createUploadFromFile({blob, ...other}, dispatch))),
  ]);
};
