import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";

export default function NotificationsPage() {
  return (
    <DashboardLayout
      titlePrefix="Benachrichtigungen"
      selectedPage="notifications"
    >
      <Box my={2}>
        <Typography variant="h4">
          Benachrichtigungen
        </Typography>
      </Box>
      <Alert variant="outlined" severity="info">
        <AlertTitle>Noch nicht umgesetzt</AlertTitle>
        Hier werden Sie Informationen erhalten, die ihre Aufmerksamkeit erfordern &ndash; wie etwa Hinweise auf neu
        eingegangene Rückmeldungen von den Verwertungsgesellschaften.
      </Alert>
    </DashboardLayout>
  );
}
