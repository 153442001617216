import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Paper,} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Alert, AlertTitle} from "@material-ui/lab";
import {ProjectTimeline} from "src/components/welcome/components";
import {makeStyles} from "@material-ui/core/styles";
import {HourglassEmpty} from "@material-ui/icons";

const useStyles = makeStyles({
  paper: {
    padding: '6px 16px',
  },
});

export default function TimelinePage() {
  const classes = useStyles();

  return (
    <DashboardLayout
      titlePrefix="Projektstatus"
      selectedPage="timeline"
    >
      <Box my={2}>
        <Typography variant="h4">
          Projektstatus
        </Typography>
      </Box>

      {/*<Box mt={2} mb={2}>*/}
      {/*  <Alert variant="filled" severity="success" color="warning" icon={<HourglassEmpty/>}>*/}
      {/*    <AlertTitle>Aktuell: Testwochen</AlertTitle>*/}
      {/*    <p>*/}
      {/*      Zur Zeit laufen koordinierte Tests gemeinsam mit den Verwertungsgesellschaften.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      Bis Ende August soll jedes an Sendemeldung.de teilnehmende Sendeunternehmen die Verarbeitung seiner*/}
      {/*      GEMAGVL4-Meldungen via Sendemeldung.de erproben und eine XML-Meldung zur Prüfung durch GEMA und GVL*/}
      {/*      freigeben. Dies ist direkt aus Sendemeldung.de heraus möglich.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      Nach erfolgreichem Abschluss der Tests erwarten wir die Freigabe durch GEMA und GVL.*/}
      {/*      Voraussichtlich ab Mitte September werden Sie erste Produktivmeldungen übermitteln können.*/}
      {/*    </p>*/}
      {/*  </Alert>*/}
      {/*</Box>*/}

      <Paper elevation={3} className={classes.paper}>
        <Box mx={4} my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            Zeitplan
          </Typography>
          <Typography gutterBottom>
            Die Entwicklung der Plattform sendemeldung.de erfolgt auf
            Veranlassung von und in enger Abstimmung mit den Verbänden APR und VAUNET.
            Nachfolgend finden Sie den aktuellen Zeitplan.
          </Typography>
        </Box>
        <ProjectTimeline elevation={3} showFuture={true}/>
      </Paper>

      {/*<Box mt={2}>*/}
      {/*  <Alert severity="info">*/}
      {/*    <AlertTitle>Hinweis zur Meldefrist</AlertTitle>*/}
      {/*    <p>*/}
      {/*      Die Möglichkeit zur fristgerechten Übermittlung von XML-Meldungen durch Sendemeldung.de ist*/}
      {/*      sichergestellt.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      Die Verpflichtung zur Meldung im neuen XML-Format gilt erst für Ihre Ausstrahlungen ab 1. Juli 2021.*/}
      {/*      Die Frist beträgt 2 Monate ab Ende des Ausstrahlungsmonats.*/}
      {/*      Ihre ersten XML-Meldungen (für den Monat Juli 2021) sind somit bis zum 30. September 2021*/}
      {/*      zu übermitteln.*/}
      {/*      Ausstrahlungen bis zum 30. Juni 2021 melden Sie wie gehabt.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      Um einen reibungslosen Übergang zu gewährleisten, führen wir derzeit gemeinsam mit den*/}
      {/*      Verwertungsgesellschaften Testwochen durch, in denen die korrekte Übermittlung von Sendemeldungen via*/}
      {/*      Sendemeldung.de für jedes teilnehmende Sendeunternehmen erprobt wird. Jedes Sendeunternehmen muss zunächst*/}
      {/*      eine Beispiel-XML-Meldung zur Übermittlung freigeben. Nach Abschluss dieser Tests und Beseitigung etwaiger*/}
      {/*      Fehler startet voraussichtlich Mitte September der vorläufige Betrieb. Sie können über*/}
      {/*      Sendemeldung.de melden, sobald wir für Ihr Sendeunternehmen eine Freigabe durch die*/}
      {/*      Verwertungsgesellschaften erhalten haben.*/}
      {/*    </p>*/}
      {/*  </Alert>*/}
      {/*</Box>*/}
    </DashboardLayout>
  );
}
