import React from "react";
import {useSelector} from "react-redux";
import {getSelectedOrganization} from "src/features/dashboard";
import {Alert, AlertTitle} from "@material-ui/lab";
import {HourglassEmpty} from "@material-ui/icons";
import {Box} from "@material-ui/core";

export default function SystemOverloadIndicator() {
  const {system_overload} = useSelector(getSelectedOrganization);

  if (!system_overload) {
    return null;
  }

  return (
    <Box mb={2}>
      <Alert variant="filled" severity="warning" icon={<HourglassEmpty/>}>
        <AlertTitle>Testumgebung ausgelastet</AlertTitle>
        <p>
          Zur Zeit gibt es mehr Zugriffe als üblich.
          Die Verarbeitung Ihrer Sendemeldungen kann sich daher verzögern.
        </p>
        <p>
          Sie müssen jedoch nicht warten!
        </p>
        <p>
          Sie können diese Webseite schließen und zu einem späteren Zeitpunkt erneut aufrufen.
          Wir verarbeiten Ihre Sendemeldungen in der Zwischenzeit automatisch, sodass Sie schon bald mit dem Ergebnis
          weiterarbeiten können.
        </p>
      </Alert>
    </Box>
  );
}
