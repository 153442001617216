import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Card, CardActions, CardContent, Grid, Step, StepContent, StepLabel, Stepper,} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {makeStyles} from "@material-ui/core/styles";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import {useSelector} from "react-redux";
import {getSelectedOrganization} from "src/features/dashboard";
import {AlertTitle} from "@material-ui/lab";
import {Info} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function SimpleCard({banner, title, subTitle, children, actions}) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {banner}
        </Typography>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {subTitle}
        </Typography>
        <Typography variant="body2" component="p">
          {children}
        </Typography>
      </CardContent>
      <CardActions>
        {actions}
      </CardActions>
    </Card>
  );
}

export default function DashboardPage() {
  const classes = useStyles();

  const organization = useSelector(getSelectedOrganization);
  let {
    gemagvl_test_priority,
    has_reports,
    has_exported_reports,
    has_published_reports,
    is_gemagvl_approved: isApproved,
  } = organization;

  let gemagvl_test_step = 0;
  if (has_published_reports) {
    gemagvl_test_step = 3;
  } else if (has_exported_reports) {
    gemagvl_test_step = 2;
  } else if (has_reports) {
    gemagvl_test_step = 1;
  }

  return (
    <DashboardLayout
      titlePrefix="Vorgänge"
      selectedPage="tasks"
    >
      <Box my={2}>
        <Typography variant="h4">
          Willkommen zu Sendemeldung.de
        </Typography>
      </Box>

      {isApproved ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <SimpleCard
                banner="Aktuell"
                title="Freigabe zur Übermittlung von Produktivdaten erhalten"
                actions={(
                  <>
                    {has_reports ? (
                      <HrefButton
                        href="/dashboard/reports/"
                        color="primary"
                        className={classes.button}
                      >
                        zu Ihren Sendemeldungen
                      </HrefButton>
                    ) : (
                      <HrefButton
                        href="/dashboard/import/"
                        color="primary"
                        className={classes.button}
                      >
                        GEMAGVL4-Sendemeldungen hochladen
                      </HrefButton>
                    )}
                  </>
                )}
              >
                <p>
                  Die Verwertungsgesellschaften haben Ihre bereitgestellte Beispiel-Sendemeldung erfolgreich
                  geprüft.
                </p>
                <p>
                  Ab sofort können Sie Ihre offiziellen Sendemeldungen über Sendemeldung.de übermitteln!
                </p>

                Den Status Ihrer Sendemeldungen aus der Testphase haben wir zurückgesetzt. Sollten Sie bereits
                Sendemeldungen für den Monat Juli 2021 für Ihre Tests verwendet haben, können Sie diese für Ihre
                Produktivmeldung wiederverwenden.
              </SimpleCard>
            </Grid>
          </Grid>
        </>
      ) : isApproved === false ? (
        <>
          {has_published_reports ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <SimpleCard
                  banner="Aktuell"
                  title="Warte auf Freigabe durch GVL und GEMA"
                >
                  <p>
                    Die Verwertungsgesellschaften prüfen derzeit Ihre bereitgestellte Testmeldung.
                  </p>
                  <p>
                    Nach erfolgreichem Abschluss erhalten wir eine Freigabe für Ihr Sendeunternehmen.
                    Sobald diese vorliegt, wird die Übermittlung von Produktivmeldungen freigeschaltet.
                  </p>
                  <p>Wir werden Sie hierüber per E-Mail informieren.</p>
                  <Box mt={3}>
                    <Alert variant="filled" severity="warning" icon={<Info/>}>
                      <AlertTitle>Hinweis zur Meldefrist</AlertTitle>
                      <p>
                        GVL und GEMA arbeiten bei der Überprüfung der Testmeldungen eng zusammen.
                        Um die Abläufe zu vereinfachen, sollen Produktivmeldungen erst übermittelt werden, wenn von
                        {' '}
                        <strong>beiden</strong> Verwertungsgesellschaften eine entsprechende Freigabe vorliegt.
                      </p>
                      <p>
                        Einige Sendeunternehmen haben bereits eine Freigabe erhalten und Produktivmeldungen über
                        Sendemeldung.de übermittelt.
                        Für den Großteil der Sendeunternehmen wird die Freigabe jedoch erst <strong>im Oktober</strong>
                        {' '}
                        erwartet.
                      </p>
                      <p>
                        Selbstverständlich sind für diese Verzögerungen, die auf GEMA/GVL zurückgehen, keine Sanktionen
                        zu befürchten:
                        Die APR hat seine Mitglieder informiert, dass die Übermittlung der Testmeldung via
                        Sendemeldung.de zur formalen Fristwahrung genügt.
                        Vom VAUNET gab es ein inhaltsgleiches Rundschreiben.
                        Dies ist auch Konsens auf der Arbeitsebene mit GEMA/GVL.
                      </p>
                      <p>
                        <HrefButton
                          href="https://www.privatfunk.de/r21/10199.html"
                          target="_blank"
                          variant="outlined"
                          color="inherit"
                          noOnClick
                        >
                          APR-Rundschreiben zur Fristwahrung
                        </HrefButton>
                      </p>
                    </Alert>
                  </Box>
                </SimpleCard>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <SimpleCard
                banner="Aktuell"
                title="Testwochen mit GVL und GEMA"
                subTitle="seit 16. August"
                actions={
                  <>
                    <HrefButton href="/dashboard/timeline/">Projektstatus</HrefButton>
                  </>
                }
              >
                {has_published_reports ? (
                  <>
                    <p>
                      Bevor Produktivmeldungen über Sendemeldung.de übermittelt werden, möchten die
                      Verwertungsgesellschaften die Verarbeitungsprozesse für die einzelnen Sendeunternehmen prüfen.
                      Bis Ende August sollte hierzu jedes Sendeunternehmen eine Beispiel-Sendemeldung hochladen, die von
                      Sendemeldung.de konvertiert und anschließend von den Verwertungsgesellschaften geprüft werden
                      kann.
                    </p>
                    <p>
                      Etwaige Probleme sollten hierdurch frühzeitig erkannt und behoben werden können, bevor
                      erste Produktivmeldungen übermittelt werden.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Bevor Produktivmeldungen über Sendemeldung.de übermittelt werden, möchten die
                      Verwertungsgesellschaften die Verarbeitungsprozesse für die einzelnen Sendeunternehmen prüfen.
                      Hierzu soll jedes Sendeunternehmen zunächst eine Beispiel-Sendemeldung hochladen, die von
                      Sendemeldung.de konvertiert und anschließend von den Verwertungsgesellschaften geprüft werden
                      kann.
                    </p>
                    <p>
                      Etwaige Probleme sollen hierdurch frühzeitig erkannt und behoben werden können, bevor erste
                      Produktivmeldungen übermittelt werden.
                    </p>
                    <Box mt={2} mb={2}>
                      <Alert variant="filled" severity="info" color="warning">
                        <AlertTitle>Wichtig</AlertTitle>
                        <p>
                          Die erfolgreiche Übermittlung einer Beispiel-Meldung ist Voraussetzung für die spätere
                          Übermittlung von Produktivmeldungen.
                        </p>
                        <p>
                          GEMA / GVL geben hierzu jedes Sendeunternehmen einzeln frei.
                          Sobald die Freigabe für Ihr Sendeunternehmen vorliegt, wird Ihnen die Übermittlung von
                          Produktivmeldungen über Sendemeldung.de ermöglicht.
                        </p>
                      </Alert>
                    </Box>
                  </>
                )}
                <Typography variant="h6">So wird getestet</Typography>
                <Stepper activeStep={gemagvl_test_step} orientation="vertical">
                  <Step>
                    <StepLabel>Sendemeldungen hochladen</StepLabel>
                    <StepContent>
                      <Typography>
                        Bitte laden Sie zunächst eine GEMAGVL4-Datei mit aktuellen Sendemeldungen hoch.
                        Wir empfehlen, direkt die Ausstrahlungen für den Monat <strong>Juli 2021</strong> zu testen.
                        Die Verwertungsgesellschaften nehmen für den Test aber auch einen früheren Meldezeitraum
                        entgegen.
                      </Typography>
                      <div className={classes.actionsContainer}>
                        <div>
                          <HrefButton
                            href="/dashboard/import/"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            GEMAGVL4-Meldungen hochladen
                          </HrefButton>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>XML-Meldungen generieren</StepLabel>
                    <StepContent>
                      <Typography>
                        Nach Upload Ihrer Sendemeldungen können Sie diese prüfen und ggf. korrigieren.
                        Anschließend erzeugen Sie via Klick auf <strong>XML-Meldung generieren</strong> die
                        XML-Meldedatei.
                      </Typography>
                      <div className={classes.actionsContainer}>
                        <div>
                          <HrefButton
                            href="/dashboard/reports/"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            zu Ihren Sendemeldungen
                          </HrefButton>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>An GEMA/GVL übermitteln</StepLabel>
                    <StepContent>
                      <Typography>
                        Ihre erzeugte XML-Meldung können Sie nun zur Übermittlung an GEMA / GVL im Rahmen der Testwochen
                        freigeben.
                        Aufgrund begrenzter Kapazitäten seitens der Verwertungsgesellschaften kann hierbei nur genau
                        eine XML-Meldung übermittelt werden &ndash; für ein Programm Ihrer Wahl.
                      </Typography>
                      <div className={classes.actionsContainer}>
                        <div>
                          <HrefButton
                            href="/dashboard/reports/"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            zu Ihren Sendemeldungen
                          </HrefButton>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Rückmeldung abwarten</StepLabel>
                    <StepContent>
                      <Typography>
                        <strong>Super!</strong>
                        {' '}
                        Sie haben erfolgreich eine XML-Meldung zur Prüfung durch GEMA / GVL freigegeben.
                        Sie müssen jetzt erstmal nichts weiter tun.
                        Wir informieren Sie, sobald Ihre Freigabe für Produktivmeldungen oder eine sonstige Rückmeldung
                        seitens der Verwertungsgesellschaften vorliegt.
                      </Typography>
                    </StepContent>
                  </Step>
                </Stepper>
                {has_published_reports ? (
                  gemagvl_test_priority === 1 ? (
                    <Alert variant="filled" severity="info" color="warning">
                      Ihr Sendeunternehmen hat von den Verwertungsgesellschaften die Prioritätsstufe
                      {' '}
                      <strong>{gemagvl_test_priority}</strong>
                      {' '}
                      erhalten und wird bevorzugt geprüft.
                    </Alert>
                  ) : gemagvl_test_priority ? (
                    <Alert variant="filled" severity="info" color="info">
                      Ihr Sendeunternehmen hat von den Verwertungsgesellschaften für die Tests die Prioritätsstufe
                      {' '}
                      <strong>{gemagvl_test_priority}</strong>
                      {' '}
                      erhalten.
                    </Alert>
                  ) : null
                ) : (
                  <Box mt={2}>
                    <Alert variant="filled" severity="info" color="info">
                      <AlertTitle>Tipp</AlertTitle>
                      Weitere Erklärungen zur Nutzung von Sendemeldung.de finden Sie im Mitschnitt unseres Webinars
                      vom 21. Juli.
                      <p>
                        <HrefButton
                          href="https://www.privatfunk.de/r21/10158.html"
                          target="_blank"
                          variant="outlined"
                          color="inherit"
                          noOnClick
                        >
                          Webinar ansehen
                        </HrefButton>
                      </p>
                    </Alert>
                  </Box>
                )}
              </SimpleCard>
            </Grid>

          </Grid>
        </>
      ) : null}

    </DashboardLayout>
  );
}
