import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Paper,} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  paper: {
    padding: '6px 16px',
  },
});

export default function ChangelogPage() {
  const classes = useStyles();

  return (
    <DashboardLayout
      titlePrefix="Änderungsprotokoll"
      selectedPage="changelog"
    >
      <Box my={2}>
        <Typography variant="h4">
          Änderungsprotokoll
        </Typography>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.1-alpha
            </Typography>

            <ul>
              <li>Fehlerbehebungen bei der Erfassung von Änderungen an Musikwerken: Im Einzelfall konnte es in der Vergangenheit vorkommen, dass Musikwerke trotz vorgenommener manueller Änderungen aus der Datenbank entfernt wurden, wenn die zugehörigen Lieferungen gelöscht wurden.</li>
              <li>Bestandsdaten in Musikdatenbanken migriert, um neue Absprachen mit GEMA / GVL zu erfüllen: Die beim GEMAGVL4-Import zu verwendenden Platzhalterlisten wurden erweitert; Ihre Musikdatenbanken wurden automatisch aktualisiert, sodass kein erneuter Import erforderlich ist.</li>
              <li>Kleinere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.1.0-alpha
            </Typography>

            <ul>
              <li>Übermittlung von Produktivmeldungen nach Abstimmung mit GEMA/GVL ermöglicht</li>
              <li>Klassifikation von Produktion-IDs aus GEMAGVL4-Daten verbessert</li>
              <li>Generierung der XML-Meldung und anschließende Übermittlung an GEMA/GVL mit einem Klick ermöglicht</li>
              <li>Weitere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.36-dev
            </Typography>

            <ul>
              <li>Verbesserte Unterstützung für GEMA-Werknummern</li>
              <li>Festlegung eines alternativen Anzeigenamens für Sendeunternehmen ermöglicht</li>
              <li>Performance-Verbesserungen</li>
              <li>Kleinere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.35-dev
            </Typography>

            <ul>
              <li>
                In Absprache mit den Verwertungsgesellschaften wurde die Musikdauer (Feld STOPPZEIT in GEMAGVL4) aus
                den von Sendemeldung.de verwalteten Datenmodellen entfernt.
                Diese Information ist zukünftig nicht mehr erforderlich.
                Etwaige Duplikate in bestehenden Musikdatenbanken, die durch den Wegfall der Stoppzeit entstanden sind,
                haben wir entsprechend zusammengeführt.
              </li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.34-dev
            </Typography>

            <ul>
              <li>Technische Anpassungen des Übermittlungsprozesses an GEMA/GVL</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.33-dev
            </Typography>

            <ul>
              <li>Formatprüfung für weitere Produktion-ID-Typen ergänzt: Katalognummer, ISWC, GEMA-Werk-Nummer, ICE,
                GVL-Produkt-ID.
              </li>
              <li>Informationen zu Testwochen aktualisiert.</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.32-dev
            </Typography>

            <ul>
              <li>Sammelaktion zur Festlegung der Musikherkunft aller bereits importierten Jingles einer Lieferung
                ergänzt
              </li>
              <li>Sammelaktion zur Behebung fehlender Musikwerk-Längen ergänzt</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.31-dev
            </Typography>

            <ul>
              <li>Performance-Verbesserungen</li>
              <li>Verschiedene Verbesserungen der Benutzeroberfläche</li>
              <li>Dateianzahlbegrenzung für ZIP-Archive erhöht</li>
              <li>Robustheit der GEMAGVL4-Importprozesse erhöht</li>
              <li>Fehlerbehebung: Fehlgeschlagene Uploads konnten nicht entfernt werden.</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.30-dev
            </Typography>

            <ul>
              <li>
                Darstellung zu bearbeitender Aufgaben verbessert und teilweise Erklärungstexte ergänzt.
              </li>
              <li>
                Generierung von XML-Meldungen bei verschiedenen vorliegenden Mängeln ermöglicht:
                Sendemeldung.de fügt in diesem Fall automatisch Platzhalter in die XML-Meldungen ein, um die formalen
                technischen Anforderungen zu erfüllen.
              </li>
              <li>
                Fehlerbehebung: Mängel an Musikwerken, die nur in Ausstrahlungen referenziert werden, die in der
                XML-Meldung ohnehin nicht ausgegeben werden (zum Beispiel wegen einer Sendedauer von 0 Sekunden),
                verhindern die Generierung von XML-Meldungen nicht mehr.
              </li>
              <li>Fehlerbehebung: Technisches Problem im Lieferant-ID-Formular</li>
              <li>Platzhalter-Liste erweitert</li>
              <li>Weitere kleinere Verbesserungen der Benutzeroberfläche</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.29-dev
            </Typography>

            <ul>
              <li>Fehlerbehebung: Hinweis auf fehlende Tonträgerinformationen bei Eigenproduktionen</li>
              <li>
                Fehlerbehebung: Möglichkeit zum Wechsel zwischen Sendeunternehmen wurde neu angelegten Nutzern erst
                nach erneutem Laden der Webseite angeboten.
              </li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.28-dev
            </Typography>

            <ul>
              <li>Informationen zu Testwochen ergänzt</li>
              <li>Löschen von Sendemeldungen ermöglicht, solange diese noch nicht exportiert wurden</li>
              <li>Darstellung von und Bearbeitungsmöglichkeiten für Musikwerke erweitert</li>
              <li>Menüstruktur leicht geändert</li>
              <li>Performance-Verbesserungen</li>
              <li>Verschiedene Fehlerbehebungen und Verbesserungen der Nutzbarkeit</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.27-dev
            </Typography>

            <ul>
              <li>Unterstützung bei Festlegung der Lieferant-ID anhand von GEMA / GVL bereitgestellter Listen</li>
              <li>Erkennung von Promo-CDs verbessert</li>
              <li>Voreinstellung für Musikherkunft neu importierter Jingle-Meldungen ist jetzt konfigurierbar.</li>
              <li>Platzhalter-Liste erweitert</li>
              <li>Fehlerbehebungen beim XML-Export</li>
              <li>Weitere Fehlerbehebungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.26-dev
            </Typography>

            <ul>
              <li>Anpassung der XML-Programmkennzeichen entsprechend Absprache mit GEMA / GVL</li>
              <li>Sendernamen auch in Bestandsdaten aktualisiert (gemäß bereitgestellter Liste von GEMA / GVL)</li>
              <li>Verschiedene Prüfungen &amp; angezeigte Aufgaben überarbeitet, um Bearbeitung zu vereinfachen</li>
              <li>Ausstrahlungen mit 0 Sekunden Sendedauer werden in XML-Ausgabe nun automatisch ausgelassen.</li>
              <li>Standardbelegung des Musikherkunft-Felds beim Import angepasst</li>
              <li>Hinweis auf fehlende Musikwerke ergänzt</li>
              <li>Fehlerbehebung: Anzeige des Lieferungszeitraums bei fehlenden Ausstrahlungszeiten</li>
              <li>Fehlerbehebung: Anzeige von Jingle-Warnungen während Import</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.25-dev
            </Typography>

            <ul>
              <li>
                Fehlerbehebung: Dateiimport wurde teilweise nicht oder verzögert abgeschlossen oder mit einem internen
                Serverfehler quittiert
              </li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.24-dev
            </Typography>

            <ul>
              <li>Programmspezifische Anpassung der tolerierten Überlappungszeit ermöglicht</li>
              <li>
                Verbesserungen beim GEMAGVL4-Import:
                <ul>
                  <li>Fehler beim Import von GEMAGVL4-Dateien mit spezifikationswidrigem NUTZUNG-Feld behoben.</li>
                  <li>Fehlerhafte Zeilen führen nun nicht mehr zur Erstellung leerer Programme oder Musikwerke.</li>
                </ul>
              </li>
              <li>Detailverbesserungen in Ausstrahlungsliste</li>
              <li>Performance-Verbesserungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.23-dev
            </Typography>

            <ul>
              <li>Performance-Verbesserungen</li>
              <li>Kleinere Fehlerbehebungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.22-dev
            </Typography>

            <ul>
              <li>Passwort-Änderung ermöglicht</li>
              <li>Performance-Verbesserungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.21-dev
            </Typography>

            <ul>
              <li>Performance-Verbesserungen</li>
              <li>Anzeige des Benutzernamens im Header</li>
              <li>Fehlerbehebung: Darstellungsprobleme beim Wechsel zwischen Sendeunternehmen</li>
              <li>Fehlerbehebung: Darstellungsprobleme in Personen-Tabelle</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.20-dev
            </Typography>

            <ul>
              <li>Programmkürzel-Listen eingebunden</li>
              <li>Hinweis auf fehlende Produktion-IDs ergänzt</li>
              <li>Änderungsprotokoll eingebunden</li>
              <li>Platzhalter-Listen aktualisiert</li>
              <li>Kleinere Verbesserungen der Nutzbarkeit</li>
              <li>Fehlerbehebungen</li>
            </ul>
          </Box>
        </Paper>
      </Box>

      <Box my={2}>
        <Paper elevation={3} className={classes.paper}>
          <Box mt={2} mb={2}>
            <Typography variant="h5">
              Version 0.0.19-dev
            </Typography>

            <ul>
              <li>Darstellung der Aufgabe, nach der gefiltert wird, in Ausstrahlungs- und Musikwerk-Liste</li>
              <li>Löschen von Aufgaben ermöglicht</li>
              <li>Anlegen weiterer Administrator-Nutzer ermöglicht</li>
              <li>Serverseitig gelöschte Datensätze werden nun live in der Benutzeroberfläche reflektiert</li>
              <li>Detailverbesserungen der Benutzeroberfläche</li>
              <li>Fehlerbehebung: Live-Aktualisierung von Datensätzen schlug vereinzelt fehl, wenn sehr viele Datensätze
                gleichzeitig dargestellt wurden
              </li>
              <li>Fehlerbehebung: Fehlender Zugriff auf Vertragsdokumente nach Nutzerkonto-Umwandlung</li>
              <li>Fehlerbehebung: Probleme bei der Aktualisierung von Ansprechpartnern</li>
            </ul>
          </Box>
        </Paper>
      </Box>
    </DashboardLayout>
  );
}
