import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {ORGANIZATION_CONTACT_ROLE_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {Button, Card, CardContent, Chip, DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi, useEntityDeleter} from "src/features/entity/entity-hooks";
import {CONTACT_ROLE} from "src/api/api-schemas";
import {useSelector} from "react-redux";
import {getContactGetter} from "src/features/entity";
import Typography from "@material-ui/core/Typography";
import {useFormContext} from "react-hook-form";
import {getProfile, hasProfileData} from "src/features/participation";

function PersonFormDialogContent({username, data, emailField}) {
  const {watch, setValue} = useFormContext();

  const setMakeAdmin = () => {
    setValue('contact.set_password', true)
  };
  const makeAdmin = watch('contact.set_password');

  const hasProfile = useSelector(hasProfileData);
  const {
    has_credentials: hasCredentials,
    has_just_set_up_credentials: hasJustSetUpCredentials,
  } = useSelector(getProfile);

  const allowAccountCreation = !hasProfile || hasCredentials || hasJustSetUpCredentials;

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <YupField name="contact.salutation"/>
        </Grid>
        <Grid item xs={6}>
          <YupField name="contact.title"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="contact.name"/>
        </Grid>
        {(username || !data?.id || !makeAdmin) ? emailField : null}

        {username ? (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Login-Berechtigung
                </Typography>
                Dieser Nutzer verfügt über eine Login-Berechtigung als <strong>Administrator</strong>.
                <br/><br/>
                <Card mt={2}>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Benutzername
                    </Typography>
                    <strong>{watch('new_username') || username}</strong>
                    {data?.is_request_user ? (
                      <>
                        {' '}
                        <Chip
                          size="small"
                          label="Sie sind als dieser Nutzer eingeloggt."
                          color="secondary"
                        />
                      </>
                    ) : null}
                  </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
        ) : !data?.id ? (
          null
        ) : makeAdmin ? (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Login-Berechtigung erstellen
                </Typography>
                <Typography gutterBottom>
                  Dieser Nutzer erhält eine Login-Berechtigung als <strong>Administrator</strong>.
                </Typography>
                <br/>

                <Grid container spacing={1}>
                  {emailField}
                  <Grid item xs={12}>
                    <YupField name="contact.new_password"/>
                  </Grid>
                  <Grid item xs={12}>
                    <YupField name="contact.passwordConfirmation"/>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ) : allowAccountCreation ? (
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setMakeAdmin(true)}
            >
              Login-Berechtigung hinzufügen
            </Button>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <YupField name="is_gl"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="is_technik"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="is_lira"/>
        </Grid>

      </Grid>
    </DialogContent>
  );
}

export function PersonForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(CONTACT_ROLE);

  const getContact = useSelector(getContactGetter);
  const {username} = getContact(data?.contact?.id);

  const savePerson = async (validatedData) => {
    const {organization, contact} = validatedData;

    const contactUuid = contact?.id;
    if (contactUuid) {
      await entityApi.patch(
        `/api/sendemeldung/organizations/${organization}/contact_roles/${contactUuid}/`,
        validatedData,
      );
    } else {
      await entityApi.post(
        `/api/sendemeldung/organizations/${organization}/contact_roles/`,
        validatedData,
        {createEntities: true, organization},
      );
    }

    // TODO: Generalize save mechanism.
  };

  const {deleteEntity, deletingUuids} = useEntityDeleter({
    entityType: 'contacts',
    baseUrl: '/api/sendemeldung/contacts/',
  });

  const emailField = (
    <Grid item xs={12}>
      <YupField name="contact.email"/>
    </Grid>
  );

  return (
    <SimpleYupFormDialog
      id={'edit-person'}
      title={data?.contact?.id ? "Person bearbeiten" : "Person anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={savePerson}
      onDelete={
        // TODO: Implement suitable confirmation mechanism.
        // TODO: Track and display deletion status.
        data?.contact?.id && !data?.is_request_user ? (
          () => confirm("Person wirklich löschen?") && deleteEntity(data?.contact?.id)
        ) : undefined
      }
      deleteCaption="Person löschen"
      isDeleting={deletingUuids?.has(data?.contact?.id)}
      schema={ORGANIZATION_CONTACT_ROLE_SCHEMA}
      {...props}
    >
      <PersonFormDialogContent
        data={data}
        emailField={emailField}
        username={username}
      />
    </SimpleYupFormDialog>
  );
}
