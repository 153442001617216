import React, {useEffect, useMemo} from "react";
import {DashboardLayout} from "src/components/layout";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import SystemOverloadIndicator from "src/components/dashboard/components/overload-indicator";
import GemaGVLXMLRow from "src/components/entities/gemagvlxml/GemaGVLXMLRow";
import {
  getInvisibleCreatedIdsByOrganizationForListing,
  getListing,
  getVisibleIdsForListing,
  updateListing
} from "src/features/ui/listing";
import {Pagination} from "src/components/paginator";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";


export default function ReportsPage() {
  const dispatch = useDispatch();

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);
  const listingId = `${selectedOrganizationId}/reports`;

  const listing = useSelector(getListing)(listingId);
  const {count} = listing;
  let totalCount = count;

  useEffect(() => {
    if (selectedOrganizationId && listingId) {
      dispatch(updateListing({
        id: listingId,
        currentPage: 1,
        pageSize: 10,
        ordering: [],
        searchQuery: '',
        endpoint: `/api/sendemeldung/organizations/${selectedOrganizationId}/gemagvlxml_lieferungen/`,
        entityType: 'gemagvlxml_lieferung',
      }));
    } else {
      // TODO: Clear listing (and/or do that in unmount effect).
    }
  }, [selectedOrganizationId, listingId]);

  const visibleIds = useSelector(getVisibleIdsForListing)(listingId);
  const createdIds = useSelector(getInvisibleCreatedIdsByOrganizationForListing)(listingId)(selectedOrganizationId);

  const renderIds = [...visibleIds, ...createdIds];

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = useMemo(() => _.memoize((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }), [setExpanded]);

  return (
    <DashboardLayout
      titlePrefix="Sendemeldungen"
      selectedPage="reports"
    >
      <Box my={2}>
        <Typography variant="h4">
          Sendemeldungen
        </Typography>
      </Box>

      <SystemOverloadIndicator/>

      {totalCount ? (
        <Grid container mb={2}>
          <Grid item xs={6}>
            <HrefButton
              href="/dashboard/import/"
              variant="outlined"
              color="action"
            >
              GEMAGVL4 hochladen
            </HrefButton>
            <br/><br/>
          </Grid>
          <Grid item xs={6}>
            <Pagination listingId={listingId}/>
          </Grid>
        </Grid>
      ) : null}

      {renderIds.length === 0 ? (
        totalCount === 0 ? (
          <Alert variant="filled" severity="info">
            Es wurden noch keine Sendemeldungen importiert.

            <p>
              <HrefButton
                href="/dashboard/import/"
                variant="contained"
                color="primary"
              >
                jetzt Sendemeldungen hochladen
              </HrefButton>
            </p>
          </Alert>
        ) : count === undefined ? (
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Sendemeldungen werden geladen...
          </Alert>
        ) : (
          <Alert variant="filled" severity="info">
            Diese Ansicht enthält keine Einträge.
          </Alert>
        )
      ) : (
        <>
          {renderIds?.map((id, i) => !id ? null : (
            <GemaGVLXMLRow
              key={id || i}
              id={id}
              expanded={expanded === id}
              expandedUuid={expanded}
              onChange={handleChange(id)}
            />
          ))}
        </>
      )}
    </DashboardLayout>
  );
}
