import {useSelector} from "react-redux";
import {getMusikPersonGetter, getOrgMusicWorkGetter} from "src/features/entity";
import {Chip} from "@material-ui/core";
import React, {useState} from "react";
import {Add} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";
import MusikPerson, {INTERPRET_LIST} from "src/components/entities/musicwork/components/MusikPerson";
import {MusicPersonForm} from "src/components/entities/musicwork/MusicPersonForm";

export default function MusikInterpreten({id, ...props}) {
  const {
    id: musikId,
    organization,
    musik_personen,
    musik_herkunft_knz,
  } = useSelector(getOrgMusicWorkGetter)(id);

  const getMusikPerson = useSelector(getMusikPersonGetter);
  const personen = musik_personen?.map(getMusikPerson);

  const interpreten = personen?.filter(({musik_person_rolle_knz}) => INTERPRET_LIST.includes(musik_person_rolle_knz));

  const [editPerson, setEditPerson] = useState(null);

  if (!musikId) {
    return (
      <Skeleton variant="text"/>
    );
  }

  const needsInterpret = (
    musik_herkunft_knz === 'EIGEN' || musik_herkunft_knz === 'LIVE' || musik_herkunft_knz === 'VTON'
  );

  const addInterpretButton = (
    <Chip
      icon={<Add/>}
      variant="outlined"
      color="primary"
      clickable
      label="Interpret ergänzen"
      onClick={() => setEditPerson({organization, org_music_work: id, musik_person_rolle_knz: 'INT'})}
    />
  );

  return (
    <>
      {interpreten?.map(({id}, i) => (
        <p key={id || i}>
          <MusikPerson
            id={id}
            showRole
            hideInterpretRole={interpreten?.length === 1}
            onEdit={setEditPerson}
            {...props}
          />
        </p>
      ))}

      {(needsInterpret && interpreten?.length === 0) ? (
        addInterpretButton
      ) : null}

      {editPerson ? (
        <div align="left">
          <MusicPersonForm
            data={editPerson}
            onClose={() => setEditPerson(null)}
          />
        </div>
      ) : null}
    </>
  );
}
