import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {MUSIC_TITLE_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {ORG_MUSIC_WORK} from "src/api/api-schemas";

export function MusicTitleForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(ORG_MUSIC_WORK);

  const saveWork = async (validatedData) => {
    const {organization, id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/organizations/${organization}/org_music_works/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'edit-music-title'}
      title={data?.id ? "Musikwerk bearbeiten" : "Musikwerk anlegen"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveWork}
      schema={MUSIC_TITLE_SCHEMA}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YupField name="musik_titel"/>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
