import {useSelector} from "react-redux";
import {getErschieneneTonaufnahmeGetter, getMusikPersonGetter, getOrgMusicWorkGetter} from "src/features/entity";
import {TableCell, TableRow} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import {Unshrinkable} from "src/packages/unshrinkable";
import NaturalTime from "src/packages/natural-time-view";
import React from "react";
import ErschieneneTonaufnahme from "./components/ErschieneneTonaufnahme";
import MusikPerson, {INTERPRET_LIST, URHEBER_LIST} from "src/components/entities/musicwork/components/MusikPerson";
import MusikTitel from "src/components/entities/musicwork/components/MusikTitel";
import {TodoTasks} from "src/components/entities/todotask/TodoTasks";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {Skeleton} from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import HrefIconButton from "src/packages/gatsby-mui-helpers/HrefIconButton";
import MusikProduktionIds from "src/components/entities/musicwork/components/MusikProduktionIds";
import MusikInterpreten from "src/components/entities/musicwork/components/MusikInterpreten";
import MusikUrheber from "src/components/entities/musicwork/components/MusikUrheber";
import MusikHerkunft from "src/components/entities/musicwork/components/MusikHerkunft";

export default function MusicWorkRow({id, onEdit}) {
  useEntityObserver({type: 'org_music_work', id});

  const data = useSelector(getOrgMusicWorkGetter)(id);
  const {
    id: orgMusicWorkId,
    organization,
    musik_titel,
    musik_personen,
    created_at,
    erschienene_tonaufnahme: erschienene_tonaufnahme_id,
    musik_produktion_ids,
    recht_knz,
    besetzung_knz,
    gattung,
    nutzung_art_knz,
    musik_herkunft_knz,
    aufnahme_datum,
    prod_titel,
    prod_ort,
    todo_tasks,
    isDeleted,
  } = data;

  const getMusikPerson = useSelector(getMusikPersonGetter);
  const personen = musik_personen?.map(getMusikPerson);

  const getErschieneneTonaufnahme = useSelector(getErschieneneTonaufnahmeGetter);
  const erschienene_tonaufnahme = getErschieneneTonaufnahme(erschienene_tonaufnahme_id);

  const interpreten = personen?.filter(({musik_person_rolle_knz}) => INTERPRET_LIST.includes(musik_person_rolle_knz));
  const urheber = personen?.filter(({musik_person_rolle_knz}) => URHEBER_LIST.includes(musik_person_rolle_knz));

  const hasTasks = todo_tasks?.length > 0;

  if (isDeleted) {
    return (
      <TableRow>
        <TableCell colSpan={8}>
          <Alert variant="filled" color="error" severity="success">
            Dieses Musikwerk wurde gelöscht.
          </Alert>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell style={hasTasks ? {borderBottom: "none"} : null}>
          {!orgMusicWorkId ? (
            <Skeleton variant="text"/>
          ) : (
            <MusikTitel id={orgMusicWorkId} createLink/>
          )}
        </TableCell>
        <TableCell style={hasTasks ? {borderBottom: "none"} : null}>
          <MusikUrheber id={id} hideEdit/>
        </TableCell>
        <TableCell style={hasTasks ? {borderBottom: "none"} : null}>
          <MusikInterpreten id={id} hideEdit/>
        </TableCell>
        <TableCell align="center" style={hasTasks ? {borderBottom: "none"} : null}>
          <MusikProduktionIds id={id} hideEdit/>
        </TableCell>
        <TableCell align="center" style={hasTasks ? {borderBottom: "none"} : null}>
          <ErschieneneTonaufnahme id={erschienene_tonaufnahme_id}/>
          <MusikHerkunft id={id} showOnlyButton/>
        </TableCell>
        <TableCell style={hasTasks ? {borderBottom: "none"} : null}>
          {created_at ? (
            <Unshrinkable><NaturalTime date={created_at}/></Unshrinkable>
          ) : null}
        </TableCell>
        <TableCell align="right" style={hasTasks ? {borderBottom: "none"} : null}>
          <HrefIconButton
            aria-label="view"
            // onClick={onEdit ? (() => onEdit({id, organization, musik_titel, recht_knz, besetzung_knz, gattung, nutzung_art_knz, musik_herkunft_knz, aufnahme_datum, prod_titel, prod_ort, erschienene_tonaufnahme})) : null}
            href={`/dashboard/archive/${id}`}
          >
            <Search/>
          </HrefIconButton>
        </TableCell>
      </TableRow>
      {hasTasks ? (
        <TableRow>
          <TableCell/>
          <TableCell colspan={6}>
            <TodoTasks ids={todo_tasks}/>
          </TableCell>
          <TableCell/>
        </TableRow>
      ) : null}
    </>
  );
}
