import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import {Link} from "gatsby-theme-material-ui";

export default function ContactPage() {
  return (
    <DashboardLayout
      titlePrefix="Service"
      selectedPage="service"
    >
      <Box my={2}>
        <Typography variant="h4">
          Service
        </Typography>
      </Box>
      <Alert variant="outlined" severity="info">
        <AlertTitle>Haben Sie eine Frage an uns?</AlertTitle>
        Kein Problem. Bitte schreiben Sie uns eine kurze E-Mail an
        {' '}
        <Link
          to="mailto:kontakt@sendemeldung.de"
        >
          kontakt@sendemeldung.de
        </Link>. Wir helfen Ihnen gerne weiter.
      </Alert>
    </DashboardLayout>
  );
}
