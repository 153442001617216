import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";

export default function SecurityPage() {
  return (
    <DashboardLayout
      titlePrefix="Sicherheit"
      selectedPage="security"
      drawerContentProps={{expandSettings: true}}
    >
      <Box my={2}>
        <Typography variant="h4">
          Sicherheit
        </Typography>
      </Box>
      <Alert variant="outlined" severity="info">
        <AlertTitle>Noch nicht umgesetzt</AlertTitle>
        Hier werden Sie Ihre Zugangsdaten verwalten und weitere sicherheitsrelevante Einstellungen vornehmen können.
      </Alert>
    </DashboardLayout>
  );
}
