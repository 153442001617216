import React from "react";
import {Chip} from "@material-ui/core";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";

export default function HrefChip({href, ...props}) {
  return (
    <HrefComponent
      wrapLink
      component={Chip}
      href={href}
      {...props}
    />
  );
}
