import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


export default function NotFoundPage() {
  return (
    <DashboardLayout
      titlePrefix="Fehler 404"
    >
      <Box my={2}>
        <Typography variant="h4">
          Fehler 404
        </Typography>
      </Box>

      Diese Seite existiert nicht.
    </DashboardLayout>
  );
}
