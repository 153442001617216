import React from "react";
import {DashboardLayout} from "src/components/layout";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import MusicWorkTable from "src/components/entities/musicwork/MusicWorkTable";
import CircularProgress from "@material-ui/core/CircularProgress";


export default function DatabasePage({listingId = 'org_music_works'}) {
  return (
    <DashboardLayout
      titlePrefix="Musikdatenbank"
      selectedPage="database"
    >
      <Box my={2}>
        <Typography variant="h4">
          Musikdatenbank
        </Typography>
      </Box>

      <MusicWorkTable
        listingId={listingId}
        noEntriesContent={(
          <Alert variant="filled" severity="info">
            Ihre Musikdatenbank enthält noch keine Einträge.
          </Alert>
        )}
        loadingContent={(
          <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
            Musikdatenbank wird geladen...
          </Alert>
        )}
      >
        <Alert variant="filled" severity="info">
          Diese Ansicht enthält keine Einträge.
        </Alert>
      </MusicWorkTable>
    </DashboardLayout>
  );
}
