import React from "react";
import {Description, FileCopy, Folder, InsertDriveFile} from "@material-ui/icons";

const FILE_TYPE_TO_ICON_COMPONENT = {
  default: InsertDriveFile,
  duplicate: FileCopy,
  container: Folder,
  gemagvl4: Description,
  gemagvlxml: Description,
};

export default function FileIcon({type, color = "action", fontSize = "large"}) {
  let Component = FILE_TYPE_TO_ICON_COMPONENT[type] || FILE_TYPE_TO_ICON_COMPONENT.default;
  return (
    <Component color={color} fontSize={fontSize}/>
  );
}
