// Adopted from react-timeago/lib/dateParser.
function dateParser(date) {
  let parsed = new Date(date);
  if (!Number.isNaN(parsed.valueOf())) {
    return parsed;
  }

  let parts = String(date).match(/\d+/g);
  if (parts == null || parts.length <= 2) {
    return parsed;
  } else {
    const [firstP, secondP, ...restPs] = parts.map(x => parseInt(x));
    const correctedParts = [firstP, secondP - 1, ...restPs];
    return new Date(Date.UTC(...correctedParts));
  }
}

export function deepCloneRequired(file) {
  // Mozilla Firefox requires us to clone the blob in some situations:
  // To avoid issues when the file vanishes during processing, we create a synchronous copy beforehand.
  // Without this workaround, file uploads via Firefox are not possible if, e.g., the drag source is Thunderbird,
  // which, unfortunately, deletes files immediately after drop.
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  if (!isFirefox) {
    // Other browser than Firefox do not seem to require cloning.
    return false;
  }

  try {
    // Workaround is only required for temporary files, i.e., files which have just been created.
    return new Date() - dateParser(file.lastModified) < 10000;
  } catch (e) {
    // We cannot determine how old the file is, so we clone it to be safe.
    return true;
  }
}

/**
 * Convert a user-provided file or blob into a blob object that can be safely used for upload.
 */
export function convertToBlob(file) {
  if (!deepCloneRequired(file)) {
    return new Promise(resolve => {
      resolve(new Blob([file], {type: file.type}));
    });
  }

  console.debug("Trying to deep-clone the file before upload to work around a Firefox issue.");
  const deepClone = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', _ => {
      resolve(new Blob([reader.result], {type: file.type}));
    });

    reader.addEventListener('error', _ => {
      reject();
    });

    reader.readAsArrayBuffer(file);
  });

  return deepClone(file).then(blob => {
    if (blob.size !== file.size) {
      throw new Error("cloned blob has unexpected size");
    }

    return blob;
  }).catch(err => {
    console.error(`Failed to clone blob: ${err}`);

    // Shallow clone might still work.
    return new Blob([file], {type: file.type});
  });
}
